import * as moment from 'moment';
import { IBirthdayRules, IBusinessRules } from '../state/model/IBusinessRules';


const hasGeburtstagsErrors = (
    dateToValidate: moment.Moment | undefined,
    isSelfInsured: boolean,
    birthdayRules: IBirthdayRules,
    selectedInsuranceStart: moment.Moment,
    isAgent: boolean
): string | boolean => {
    const MAX_AGE_LIMIT = 70;
    const AGE_LIMIT_NON_AGENT = 61;
    const GENERAL_ERROR = isAgent ? "Wir können Ihnen derzeit diese Versicherung online nicht anbieten. Bitte wenden Sie sich für ein persönliches Angebot an Ihren ERGO Berater" : "Wir können Ihnen derzeit diese Versicherung online nicht anbieten. Bitte wenden Sie sich für ein persönliches Angebot an unseren gebührenfreien Beratungsservice."
    const INVALID_AGE_ERROR = 'Bitte geben Sie ein gültiges Datum an.';
    const UNDERAGE_AGE_ERROR = 'Wir können Ihnen diese Versicherung nur anbieten, wenn Sie mindestens 18 Jahre alt sind.';
    const DATE_PAST_ERROR = 'Bitte wählen Sie ein Datum, das in der Vergangenheit liegt.';

    if (!dateToValidate || !dateToValidate.isValid()) {
        return INVALID_AGE_ERROR;
    }
    if (isSelfInsured && dateToValidate.startOf('day').isAfter(moment.utc(birthdayRules.dateSpanMinForVn), 'day')) {
        return UNDERAGE_AGE_ERROR;
    }
    if (isSelfInsured && moment.duration(selectedInsuranceStart.diff(dateToValidate)).asYears() > MAX_AGE_LIMIT) {
        return GENERAL_ERROR;
    }
    if (isAgent && isSelfInsured && moment.duration(selectedInsuranceStart.diff(dateToValidate)).asYears() > AGE_LIMIT_NON_AGENT) {
        return GENERAL_ERROR;
    }
    if (!isSelfInsured && dateToValidate.startOf('day').isAfter(moment.utc(birthdayRules.dateSpanMinForVp), 'day')) {
        return DATE_PAST_ERROR;
    }
    if (!isSelfInsured && dateToValidate.startOf('day').isSameOrBefore(birthdayRules.dateSpanMaxForVp, 'day')) {
        return GENERAL_ERROR;
    }

    return false;
};

interface IBirthdayValidator {
    businessRules: IBusinessRules;
    insurerBirthDate?: moment.Moment | undefined;
    insuredBirthDate?: moment.Moment | undefined;
    isSelfInsured: boolean;
    isAgent: boolean;
    selectedInsuranceStart: moment.Moment
}

const hasBirthdayErrors = ({
    businessRules,
    insurerBirthDate,
    insuredBirthDate,
    isSelfInsured,
    isAgent,
    selectedInsuranceStart
}: IBirthdayValidator): boolean => {

    const hasValidationError = [
        !businessRules,
        hasGeburtstagsErrors(insurerBirthDate, true, businessRules.birthdayRules, selectedInsuranceStart, isAgent),
        !isSelfInsured && hasGeburtstagsErrors(insuredBirthDate, false, businessRules.birthdayRules, selectedInsuranceStart, isAgent)
    ].some(Boolean);

    return hasValidationError;
}

const berechneEintrittsalter = (versicherungsBeginn: moment.Moment, geburtsdatum: moment.Moment) => {
    return versicherungsBeginn.diff(geburtsdatum, 'years');
};

export {
    hasGeburtstagsErrors,
    hasBirthdayErrors,
    berechneEintrittsalter
};
