import { THEME } from '@eg/elements/components/Provider';
import Tarifvariante from '../../../state/model/Tarifvariante';

export interface IBlock {
    name: string;
    onClick: () => void;
    values: {
        [key: string]: string;
    };
}

export interface ITarifdaten {
    versicherungsBeginn: string;
    blocks?: {
        [key: string]: string;
    };
}

export interface IBeitragsdaten {
    zahlweise: string;
    beitrag: number;
    onBeitragsdatenChanged?: () => void;
}

export interface IBankdaten {
    iban: string;
    onBankverbindungChanged?: () => void;
}

export interface IAdresse {
    strasse: string;
    hausnummer: string;
    plz: string;
    ort: string;
}

export interface IVersicherungsnehmer {
    anrede: string;
    vorname: string;
    nachname: string;
    geburtsdatum: string;
    eMail: string;
    bankdaten: IBankdaten;
    adresse: IAdresse;
    vorwahl?: string;
    rufnummer?: string;
    titelZusatz?: string;
    onVersicherungsnehmerChanged?: () => void;
}

export interface IExternalSubcomponent {
    headline: string;
    mountingPoint: string;
    onChangeData: () => void;
}

export interface IConsent {
    title: string;
    text: string;
    errorMsg: string;
}

export interface ICheckoutModel {
    beitragsdaten: IBeitragsdaten;
    tarifdaten: ITarifdaten;
    versicherungsnehmer: IVersicherungsnehmer;
    blocks?: IBlock[];
    extSubcomponents?: IExternalSubcomponent[];
    consentBoxes: IConsent[];
    sparte: Sparte;
}

export interface IAdditionalSzIddPdfRequestProperties {
    businessId: string;
    tarif: Tarifvariante;
    vermittlerPDFMitschicken: boolean;
}

export interface ICallbacks {
    onRenderComplete: () => void;
    onError: (e: Error) => void;
    onReportValidity: (valid: boolean) => void;
}
export interface PdfDownloadControls {
    downloadButtonLoading: boolean;
    downloadButtonDisabled: boolean;
    onDownloadButtonClick: () => void;
}
export interface ICheckoutInput {
    theme: THEME;
    model: ICheckoutModel;
    documentDownload: PdfDownloadControls;
    isIddErrorMessageDisplayed: boolean;
    additionalIddPdfRequestProperties: IAdditionalSzIddPdfRequestProperties;
    callbacks: ICallbacks;
    backendHost: string;
}

export enum Sparte {
    ERGO_KRANKENVERSICHERUNG = 'ERGO Krankenversicherung AG'
}
