import * as React from 'react';

const OnlineRejectionText: React.StatelessComponent = () => {
    return (
        <>
            Vielen Dank, dass Sie die Gesundsheitsfragen beantwortet haben.
            <br/><br/>
            Leider können wir Ihnen keinen Online-Abschluss anbieten.
            <br/><br/>
            Bitte haben Sie dafür Verständnis.
        </>
    );
};

export default OnlineRejectionText;
