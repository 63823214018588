import { mapToDuwInputEventModel, mapToDuwInputPayloadForReadonlyMode } from './mapper/duwMapper';
import { ICallbacks } from './model/IDuwInput';
import { THEME } from '@eg/elements/components/Provider';
import * as moment from 'moment';
import IPerson from '../../state/model/IPerson';

export interface IDuwMessaging {
    dispatchRenderFormMode: (
        token: string | undefined,
        theme: THEME,
        apiRootUrl: string,
        vn: IPerson,
        precontractualNotification: string,
        callbacks: ICallbacks,
        versicherungsBeginn: moment.Moment
    ) => void;
    dispatchRenderReadonlyMode: (
        token: string | undefined,
        theme: THEME,
        apiRootUrl: string,
        errorCallback: (e: Error) => void,
        person: IPerson
    ) => void;
    dispatchNextClicked: () => void;
    dispatchUnmount: () => void;
}

export const duwMessaging: IDuwMessaging = {
    dispatchRenderFormMode: (token, theme, apiRootUrl, person, precontractualNotification, callbacks, versicherungsBeginn) => {
        const duwInput = mapToDuwInputEventModel(
            token,
            theme,
            apiRootUrl,
            person,
            precontractualNotification,
            callbacks,
            versicherungsBeginn
        );
        const changeEvent = new CustomEvent('duw_render', {detail: duwInput});
        document.dispatchEvent(changeEvent);
    },
    dispatchRenderReadonlyMode: (token, theme, apiRootUrl, errorCallback, person) => {
        const payload = mapToDuwInputPayloadForReadonlyMode(token, theme, apiRootUrl, errorCallback, person);
        const event = new CustomEvent('duw_render', {detail: payload});
        document.dispatchEvent(event);
    },
    dispatchNextClicked: () => {
        document.dispatchEvent(new CustomEvent('duw_nextClicked'));
    },
    dispatchUnmount: () => {
        document.dispatchEvent(new CustomEvent('duw_unmount'));
    },
};
