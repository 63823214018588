import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { IDuwMessaging } from '../../messaging/duw/duwMessaging';
import Navigation from '../Navigation';
import childAppStatus from '../../state/model/childAppStatus';
import DefaultButtonZurueck from '../DefaultButtonZurueck';
import { Steps } from '../../consts';
import ArrowRightIcon from '@eg/elements/components/Icons/ArrowRightIcon';
import { IBasisData } from '../../messaging/duw/model/IDuwInput';
import { THEME } from '@eg/elements/components/Provider';
import LoadingSpinner from '@eg/elements/components/LoadingSpinner';
import Button from '@eg/elements/components/Button';
import EditIcon from '@eg/elements/components/Icons/EditIcon';
import PreContractualInformation from './PreContractualInformation';
import IPerson from '../../state/model/IPerson';
import moment from 'moment';

export interface IDuwProps {
    duwAppLoadingState: childAppStatus;
    person: IPerson;
    messaging: IDuwMessaging;
    onFinalize: () => void;
    onError: (e: Error) => void;
    onNavigate: (steps: number) => void;
    onNotifyCaseId: (token: string, baseData: IBasisData) => void;
    onResetDuwResult: (callback: () => void) => void;
    pdfLocation: string;
    theme: THEME;
    apiRootUrl: string;
    token?: string;
    hasResult: boolean;
    tracking: () => void;
    versicherungsBeginn: moment.Moment
}

interface IDuwState {
    isLoading: boolean;
}

class Duw extends React.Component<IDuwProps, IDuwState> {
    constructor(props: IDuwProps, state: IDuwState) {
        super(props, state);

        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        this.props.tracking();

        if (this.props.duwAppLoadingState === childAppStatus.loadingSuccessfullyFinished) {
            this.dispatchRender(this.props.token);
        }
    }

    componentWillUnmount() {
        this.props.messaging.dispatchUnmount();
    }

    dispatchRender = (token: string | undefined) => {
        if (!this.props.hasResult) {
            this.props.messaging.dispatchRenderFormMode(
                token,
                this.props.theme,
                this.props.apiRootUrl,
                this.props.person,
                ReactDOMServer.renderToStaticMarkup(<PreContractualInformation pdfLocation={this.props.pdfLocation}/>),
                {
                    onError: this.props.onError,
                    onFinalize: () => {
                        this.setState({isLoading: true});
                        this.props.onFinalize();
                    },
                    onNotifyCaseId: (newToken: string, baseData: IBasisData) => {
                        this.setState({isLoading: false});
                        if (newToken === this.props.token) {
                            return;
                        }
                        this.props.onNotifyCaseId(newToken, baseData);
                    }
                },
                this.props.versicherungsBeginn
            );
        } else {
            this.props.messaging.dispatchRenderReadonlyMode(
                token,
                this.props.theme,
                this.props.apiRootUrl,
                this.props.onError,
                this.props.person);
        }
    };

    handleEditAnswers = () => {
        // childapp unmounten und später erneut im FormMode mounten
        this.props.messaging.dispatchUnmount();

        this.props.onResetDuwResult(() => this.dispatchRender(undefined));
    };

    render() {
        let result: JSX.Element | null = null;

        // tslint:disable-next-line:no-empty
        let weiterFx: (() => void) | undefined = undefined;

        switch (this.props.duwAppLoadingState) {
            case childAppStatus.loadingInProgress:
                result = <LoadingSpinner show={true}/>;
                break;

            case childAppStatus.loadingSuccessfullyFinished:
                result = <div id="root-duw"/>;

                if (this.props.hasResult) {
                    // Add "Angaben ändern" buttons to the top and the bottom of the readonly view
                    const changeBtn = (
                        <Button
                            type="button"
                            iconLeft={<EditIcon/>}
                            variant="text-link"
                            onClick={this.handleEditAnswers}
                            data-component-id="button_edit_duw"
                        >
                            Angaben ändern
                        </Button>);
                    result = (
                        <>
                            <br/>
                            {changeBtn}
                            {result}
                            {changeBtn}
                        </>
                    );

                    weiterFx = () => {
                        this.props.onNavigate(Steps.BeitragAktualisiert);
                    };
                } else {
                    weiterFx = () => {
                        this.props.messaging.dispatchNextClicked();
                    };
                }

                break;

            default:
                break;
        }

        return (
            <>
                {result}

                <br/>
                <Navigation
                    ctaBack={<DefaultButtonZurueck navigate={() => {
                        this.props.onNavigate(Steps.BeitragVorlaeufig);
                    }}/>}
                    ctaWeiter={
                        <Button
                            iconRight={ArrowRightIcon}
                            data-component-id="button_weiter"
                            variant="primary"
                            loading={this.state.isLoading}
                            onClick={weiterFx}
                        >
                            Weiter
                        </Button>
                    }
                />
            </>
        );
    }
}

export default Duw;
