import { IPersonVp } from '../../../state/model/IPersonVp';
import { IVnInput } from '../model/input/IVnInput';
import { IVpInput } from '../model/input/IVpInput';
import { ICallbacks, IPersonendatenInput, PersonendatenRepository } from '../model/input/IPersonendatenInput'
import { THEME } from '@eg/elements/Provider';
import IAngebot from '../../../state/model/IAngebot';
import { Role } from '../model/Role';
import { IPromotionInput } from '../model/input/IPromotionInput';
import moment from 'moment';
import { isInsureeEqualToInsuredPerson } from '../../../state/helperFunctions';

const mapVnToService = (angebot: IAngebot): IVnInput => {
    const vn = angebot.personenDaten.vn;

    return {
        geschlecht: vn.geschlecht,
        vorname: vn.vorname,
        nachname: vn.nachname,
        adresse: vn.adresse,
        id: vn.id,
        role: Role.VN,
        email: vn.email,
        einverstaendnisEmailWerbung: vn.einverstaendnisEmailWerbung,
        vorwahl: vn.vorwahl,
        rufnummer: vn.rufnummer,
        geburtsdatum: (vn.geburtsdatum as moment.Moment).toDate(),
        iban: vn.iban,
        isSexReadOnly: Boolean(angebot.token) && isInsureeEqualToInsuredPerson(angebot.personenDaten)
    };
};

const mapVpToService = (angebot: IAngebot): IVpInput => {
    const vp = angebot.personenDaten.vp as IPersonVp;

    return {
        geschlecht: vp.geschlecht,
        vorname: vp.vorname,
        nachname: vp.nachname,
        adresse: vp.adresse,
        id: vp.id,
        role: Role.VP,
        geburtsdatum: (vp.geburtsdatum as moment.Moment).toDate(),
        isSexReadOnly: Boolean(angebot.token) && !isInsureeEqualToInsuredPerson(angebot.personenDaten)
    };
};

const mapToServiceModel = (
    angebotsdaten: IAngebot,
    theme: THEME,
    apiRootUrl: string | undefined,
    callbacks: ICallbacks,
    promotion: IPromotionInput): IPersonendatenInput => {
    const vps: IVpInput[] = (angebotsdaten.personenDaten.vp && angebotsdaten.personenDaten.vp.geburtsdatum !== null)
        ? [mapVpToService(angebotsdaten)]
        : [];

    return {
        repository: PersonendatenRepository.SPCS,
        addressValidation: true,
        vn: mapVnToService(angebotsdaten),
        vps,
        angebotsId: angebotsdaten.angebotId as string,
        theme,
        apiRootUrl,
        creditor: {
            name: 'ERGO Krankenversicherung AG',
            identNumber: 'DE52EDK00000041713'
        },
        callbacks,
        disableEwe: true,
        promotion
    };
};

export { mapToServiceModel };
