import * as React from 'react';
import MessageBox from '@eg/elements/components/MessageBox';
import styled from 'styled-components';
import { DuwErgebnis } from '../../infrastructure/model/DuwErgebnis';

interface IDuwInfoProps {
    duwResult: DuwErgebnis;
    erhoehterBeitrag: boolean;
    exclusions: string[];
}

const StyledList = styled.ul`
            padding: 0px 20px
            list-style-type: disc
        `;

const DuwInfo = (props: IDuwInfoProps) => {
    if (props.duwResult === DuwErgebnis.MANUELLE_RISIKOPRUEFUNG) {
        return (
            <MessageBox data-component-id="info-manuelle-risikopruefung-duw">
                <p>Vielen Dank, dass Sie die Gesundheitsfragen beantwortet haben.</p>
                <br/>
                <p>
                    Gern prüft der Versicherer, ob er Ihnen die Krankenhauszusatzversicherung anbieten kann.
                    Bitte geben Sie dazu im nächsten Schritt Ihre persönlichen Daten ein.
                </p>
            </MessageBox>
        );
    }
    return (
        <MessageBox data-component-id="info-positive-duw">
            <div>
                <b>Ihr persönlicher Beitrag {props.erhoehterBeitrag ? ' inkl. Risikozuschlägen' : ''}</b>
                <br/><br/>
                <p>
                    Vielen Dank, dass Sie die Gesundheitsfragen beantwortet haben.
                    {props.erhoehterBeitrag ? ' Aus Ihren Angaben ergibt sich Ihr neuer Beitrag.' : ''}
                </p>
                <br/>
            </div>
            {
                props.exclusions && props.exclusions.length > 0 &&
                <>
                    <p>
                        Bitte beachten Sie: Vom Versicherungsschutz ausgeschlossen ist/sind die nachfolgend aufgeführte(n)
                        Funktionsstörung(en)/Erkrankung(en) sowie alle Folgen und Komplikationen:
                    </p>
                    <br/>
                    <StyledList>
                        {props.exclusions.map((exclusion: string) => (
                            <li key={exclusion}>{exclusion}</li>
                        ))}
                    </StyledList>
                    <br/>
                </>
            }
            <p>Bitte geben Sie im nächsten Schritt Ihre persönlichen Daten ein.</p>
        </MessageBox>
    );
};

export default DuwInfo;
