import { THEME } from '@eg/elements/components/Provider';
import { ICallbacks, IDuwInput } from '../model/IDuwInput';
import * as moment from 'moment';
import Geschlecht from '../../../state/model/Geschlecht';
import IPerson from '../../../state/model/IPerson';
import { berechneEintrittsalter } from '../../../businessRules/birthdayRules';

const mapToCommon = (
    token: string | undefined,
    theme: THEME,
    apiRootUrl: string,
    {groesse, gewicht, geschlecht}: IPerson
): IDuwInput => {
    const basisData = groesse && gewicht && geschlecht
        ? {groesse, gewicht, geschlecht: geschlecht === Geschlecht.WEIBLICH ? 'WEIBLICH' : 'MAENNLICH'}
        : undefined;

    return {
        token,
        theme,
        apiBaseUrl: apiRootUrl,
        basisData
    };
};

const mapToDuwInputEventModel = (
    token: string | undefined,
    theme: THEME,
    apiRootUrl: string,
    person: IPerson,
    preContractualInformation: string,
    callbacks: ICallbacks,
    versicherungsBeginn: moment.Moment
): IDuwInput => ({
    ...mapToCommon(token, theme, apiRootUrl, person),
    formMode: {
        preContractualInformation,
        statischeDaten: {
            alter: berechneEintrittsalter(versicherungsBeginn, person.geburtsdatum as moment.Moment)
        },
        callbacks,
        isDuwRestartable: true,
        tarif: 'SZ',
        mandant: 'ERGO_DIREKT'
    }
});

const mapToDuwInputPayloadForReadonlyMode = (token: string | undefined, theme: THEME, apiRootUrl: string, onError: (e: Error) => void, person: IPerson): IDuwInput => ({
    ...mapToCommon(token, theme, apiRootUrl, person),
    readonlyMode: {
        callbacks: {
            onError
        }
    }
});

export { mapToDuwInputEventModel, mapToDuwInputPayloadForReadonlyMode };
