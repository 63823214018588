import * as React from 'react';

type PromotionFootnoteProps = {
    content: string;
};

const PromotionFootnote = (props: PromotionFootnoteProps) =>
     props.content ? <span dangerouslySetInnerHTML={{ __html: props.content }} /> : null;

export default PromotionFootnote;
