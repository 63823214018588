import * as React from 'react';
import Geschlecht from '../../state/model/Geschlecht';
import { Versandart } from '../../consts';
import OfferText from './OfferText';
import OnlineCompletionText from './OnlineCompletionText';
import OnlineRejectionText from './OnlineRejectionText';
import MessageBox from '@eg/elements/MessageBox';
import { PromotionFeedback } from '@eg/aktionskennwort';
import { isOnlineAbschlussMoeglich } from '../../businessRules/duwRules';
import { DuwErgebnis } from '../../infrastructure/model/DuwErgebnis';
import { IStaticValues } from '../../state/model/IStaticValues';

export interface IFeedbackProps {
    versandart: Versandart;
    duwErgebnis?: DuwErgebnis;
    tracking: () => void;
    geschlecht: Geschlecht;
    vorname: string;
    nachname: string;
    promotionSuccessful?: boolean;
    businessId: string;
    versicherungsnehmerPdsId: string;
    staticValues: IStaticValues;
}

class Feedback extends React.Component<IFeedbackProps> {

    private static getFormOfAddress(gender: Geschlecht, firstName: string, lastName: string) {
        const formOfAddress = gender === Geschlecht.WEIBLICH ? ' Frau' : 'r Herr';
        return (formOfAddress) ? `Sehr geehrte${formOfAddress} ${firstName} ${lastName},` : '';
    }

    componentDidMount() {
        this.props.tracking();
    }

    render() {
        const contactFormLink = <a href="/de/Service/Kontakt/Allgemeine-Kontaktdaten/Kontaktformular.html ">E-Mail</a>;
        const formOfAddress = Feedback.getFormOfAddress(this.props.geschlecht, this.props.vorname, this.props.nachname);

        return (
            <>
                <MessageBox type="info">
                    {this.props.versandart === Versandart.Email || this.props.versandart === Versandart.Postalisch
                        ? (
                            <OfferText
                                contactFormLink={contactFormLink}
                                versandart={this.props.versandart}
                                formOfAddress={formOfAddress}
                            />
                        )
                        : isOnlineAbschlussMoeglich(this.props.duwErgebnis)
                            ? (
                                <OnlineCompletionText
                                    contactFormLink={contactFormLink}
                                    formOfAddress={formOfAddress}
                                    businessId={this.props.businessId}
                                    versicherungsnehmerPdsId={this.props.versicherungsnehmerPdsId}
                                    showBetreuungsweiche={!this.props.staticValues.agentur && this.props.staticValues.betreuungsweicheOn === true}
                                />
                            )
                            : <OnlineRejectionText/>}
                </MessageBox>

                {this.props.promotionSuccessful && <PromotionFeedback
                    title="Ihr Gutschein"
                    text="Ihren Amazon.de Gutschein erhalten Sie spätestens 4 Monate nach Vertragsbeginn"
                />}
            </>
        );
    }
}

export default Feedback;
