import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createClient } from './infrastructure/gatewayClient';
import axios from 'axios';
import config from './config/config';
import { personendatenMessaging } from './messaging/personendatenerfassung/personendatenerfassungMessaging';
import * as cookies from 'js-cookie';
import '@eg/elements/styles/styles.css';
import { checkoutMessaging } from './messaging/checkout/checkoutMessaging';
import { IChildApp } from './state/model/IChildApp';
import { duwMessaging } from './messaging/duw/duwMessaging';
import ErrorBoundary from './components/ErrorBoundary';
import ReactResizeDetector from 'react-resize-detector';
import { getAemConfig } from './config/aem/aemConfigHelper'
import mapToStaticValues from './config/aem/aemConfigMapper'

const api = createClient(axios, config.baseBackendUrl);
const aemConfig = getAemConfig();

const cookieValues = cookies.get();

const staticValues = mapToStaticValues(
    aemConfig,
    window.location.search.indexOf('SimulateKooperation') > -1 ? '5169%2C815000331' : cookieValues.aktionNr,
    window.location.search,
    window.location.search.indexOf('SimulateKooperation') > -1 ? 'FAKE-FREMD-ID' : cookieValues.fid);

config.errorPolicy.configure();

const personendaten: IChildApp = {
    url: config.urlPersonendaten,
    messaging: personendatenMessaging
};

const checkout: IChildApp = {
    url: config.urlCheckout,
    messaging: checkoutMessaging,
    customFailHandler: config.customFailHandlerCheckout,
};

const duw: IChildApp = {
    url: config.urlDuw,
    messaging: duwMessaging,
    customFailHandler: config.customFailHandlerDuw
};

const root = document.getElementById('szsszl-root') as HTMLElement;

ReactDOM.render(
    <React.StrictMode>
        { // Aktivierung Resize-Nachricht aus iframe im Agenturfall
            staticValues.agentur &&
            <ReactResizeDetector
                handleWidth={false}
                handleHeight={true}
                onResize={() => {
                    if (root) {
                        window.parent.postMessage(root.offsetHeight, '*');
                    }
                }}
            />
        }
        <ErrorBoundary
            personendaten={personendaten}
            checkout={checkout}
            duw={duw}
            api={api}
            disableAemSpinner={disableAemSpinner}
            staticValues={staticValues}
            logError={config.errorPolicy.logError}
            scrollToTop={() => { window.scroll(0, 0); }}
        />
    </React.StrictMode>,
    root
);

function disableAemSpinner() {
    const spinner = document.getElementById('ed_tarifspinner');

    if (spinner && spinner.parentNode) {
        spinner.parentNode.removeChild(spinner);
    }
}
