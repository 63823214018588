import ArrowLeftIcon from '@eg/elements/components/Icons/ArrowLeftIcon';
import * as React from 'react';
import Button from '@eg/elements/components/Button';

interface IDefaultButtonZurueckProps {
    navigate: (step: number) => void;
}

const DefaultButtonZurueck = (props: IDefaultButtonZurueckProps) => {
    return (
        //
        <Button
            data-component-id="button_zurueck"
            variant="text-link"
            iconLeft={ArrowLeftIcon}
            onClick={props.navigate as any}
        >
            zurück
        </Button>
    );
};

export default DefaultButtonZurueck;
