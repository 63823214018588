import * as React from 'react';
import { Button } from '@eg/elements/Button';
import { LoadingSpinner } from '@eg/elements/LoadingSpinner';
import Navigation from './Navigation';
import { Steps, Versandart } from '../consts';
import childAppStatus from '../state/model/childAppStatus';
import { IPersonendatenMessaging } from '../messaging/personendatenerfassung/personendatenerfassungMessaging';
import EmailIcon from '@eg/elements/components/Icons/EmailIcon';
import MailIcon from '@eg/elements/components/Icons/MailIcon';
import ArrowRightIcon from '@eg/elements/components/Icons/ArrowRightIcon';
import AnfrageTyp from '../messaging/personendatenerfassung/model/AnfrageTyp';
import DefaultButtonZurueck from './DefaultButtonZurueck';
import IAngebot from '../state/model/IAngebot';
import { THEME } from '@eg/elements/components/Provider';
import { IPersonendatenChangedOutput } from '../messaging/personendatenerfassung/model/output/IPersonendatenChangedOutput';
import IPromotion from '../state/model/IPromotion';
import { mapToPromotionInput } from './mapper/promotionMapper';
import { IPersonendatenMeta } from '../state/model/IPersonendaten';
import { DuwErgebnis } from '../infrastructure/model/DuwErgebnis';
import { isOnlineAbschlussMoeglich } from '../businessRules/duwRules';
import {
    IPersonendatenFinishedOutput
} from '../messaging/personendatenerfassung/model/output/IPersonendatenFinishedOutput'

interface IPersoenlichesState {
    isSubmitButtonLoading: boolean;
    selectedShippingMethod?: Versandart;
}

export interface IPersoenlichesProps {
    theme: THEME;
    apiRootUrl?: string,
    personenDatenAppLoadingState: childAppStatus;
    offerData: IAngebot;
    moeglicheAbschlussArten: IPersonendatenMeta;
    onNavigate: (newStep: number) => void;
    messaging: IPersonendatenMessaging;
    tracking: () => void;
    onError: (e: Error) => void;
    onChangedPersonalData: (output: IPersonendatenChangedOutput) => void;
    tariff: string | undefined;
    aktionsnummer: string;
    promotion?: IPromotion;
    isDuwPossible: boolean;
    duwErgebnis?: DuwErgebnis;
    onFinalize: (output: IPersonendatenFinishedOutput, versandart: Versandart) => void;
}

class Persoenliches extends React.Component<IPersoenlichesProps, IPersoenlichesState> {
    constructor(props: IPersoenlichesProps, state: IPersoenlichesState) {
        super(props, state);

        this.state = {
            isSubmitButtonLoading: false,
            selectedShippingMethod: undefined
        };

        this.isDAMoeglich = this.isDAMoeglich.bind(this);
    }
    handleValidationFailed = () => {
        this.setState( { isSubmitButtonLoading: false } );
    }
    handleSubmit = (shippingMethod: Versandart) => {
        this.setState({
            selectedShippingMethod: shippingMethod,
            isSubmitButtonLoading: true
        },
        () => {
            this.props.messaging.dispatchNextClicked(shippingMethod === Versandart.Online
                ? AnfrageTyp.DIREKTABSCHLUSS
                : AnfrageTyp.ANGEBOT);
        });
    };

    isDAMoeglich() {
        return this.props.isDuwPossible && isOnlineAbschlussMoeglich(this.props.duwErgebnis);
    }

    dispatchRenderPersonendaten = () => {
        if (!this.props.isDuwPossible) {
            this.props.offerData.personenDaten.vn.iban = undefined;
        }

        this.props.messaging.dispatchRenderPersonendaten(
            this.props.offerData,
            this.props.theme,
            this.props.apiRootUrl,
            {
                onError: this.props.onError,
                onChangedPersonendaten: this.props.onChangedPersonalData,
                onValidationFailed: this.handleValidationFailed,
                onSubmitPersonendaten: () => {
                    this.handleSubmit(this.isDAMoeglich() ? Versandart.Online : Versandart.Email);
                },
                onFinishedPersonendaten: (output: IPersonendatenFinishedOutput) => {
                    this.props.onFinalize(output, this.state.selectedShippingMethod as Versandart);
                }
            },
            mapToPromotionInput(this.props.tariff, this.props.aktionsnummer, this.props.promotion)
        );
    };

    componentDidMount() {
        if (this.props.personenDatenAppLoadingState === childAppStatus.loadingSuccessfullyFinished) {
            this.dispatchRenderPersonendaten();
        }

        this.props.tracking();
    }

    componentDidUpdate(prevProps: IPersoenlichesProps) {
        // handle case when loading was too slow
        if (prevProps.personenDatenAppLoadingState === childAppStatus.loadingInProgress
            && this.props.personenDatenAppLoadingState === childAppStatus.loadingSuccessfullyFinished) {

            this.dispatchRenderPersonendaten();
        }
    }

    componentWillUnmount() {
        this.props.messaging.dispatchUnmountPersonendaten();
    }

    render() {
        // note that DUW result ABLEHNUNG will never be navigated to this component (instead, directly to Feeback.tsx)
        const cta = this.isDAMoeglich()
            ? (
                <Button
                    type="submit"
                    data-component-id="button_weiter"
                    iconRight={ArrowRightIcon}
                    variant="primary"
                    loading={this.state.isSubmitButtonLoading}
                    onClick={() => this.handleSubmit(Versandart.Online)}
                >
                    Weiter
                </Button>)
            : (
                <>
                    <Button
                        type={'submit'}
                        data-component-id="button_angebotEmail"
                        iconLeft={EmailIcon}
                        variant="primary"
                        loading={this.state.isSubmitButtonLoading}
                        onClick={() => this.handleSubmit(Versandart.Email)}
                    >
                        Angebot per E-Mail
                    </Button>
                    <br/>
                    <Button
                        variant="text-link"
                        data-component-id="button_angebotPost"
                        iconLeft={MailIcon}
                        loading={this.state.isSubmitButtonLoading}
                        onClick={() => this.handleSubmit(Versandart.Postalisch)}
                    >
                        Angebot per Post
                    </Button>
                </>
            );

        let result;

        switch (this.props.personenDatenAppLoadingState) {
            case childAppStatus.loadingInProgress:
                result = <LoadingSpinner show={true}/>;
                break;

            case childAppStatus.loadingSuccessfullyFinished:
                result = (
                    <>
                        <div data-component-id="personendaten_app" id="root-personendaten"/>
                        <br/>
                        <Navigation
                            ctaBack={<DefaultButtonZurueck navigate={() => this.props.onNavigate(Steps.BeitragVorlaeufig)}/>}
                            ctaWeiter={cta}
                        />
                        <div id="annotation-text"/>
                    </>
                );
                break;

            default:
                break;
        }

        return result;
    }
}

export default Persoenliches;
