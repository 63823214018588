import { IStaticValues } from '../../state/model/IStaticValues';
import { THEME } from '@eg/elements/components/Provider';
import * as qs from 'qs';
import { IKooperationsPartner } from '../../infrastructure/model/request/ICalculationRequest';
import { AemConfig } from '../../types/AemConfig'

const getSplittedCookieValue = (cookieValue: string, isAgentur: boolean): { aktionNr: string, kooperationsPartnerId: string } => {
    if (isAgentur) {
        return {
            aktionNr: '8795',
            kooperationsPartnerId: ''
        };
    }

    // Böse, böse, böse: der AEM-Cookie-Wert enthält ein Komma am Ende. Bei Kooperationspartnern hängt dann noch die Vermittlernummer dahinter
    // Aber es wird noch besser: Teilweise ist das Komma URL-encoded (%2C)
    cookieValue = decodeURIComponent(cookieValue);

    const values = cookieValue.split(',').map(item => item.trim());

    const reducer = (accumulator: string, currentValue: string, isConditionFulfilled: (value: string) => boolean) => {
        if (!isConditionFulfilled(currentValue)) {
            return accumulator;
        }
        return `${accumulator}${accumulator ? ',' : ''}${currentValue}`;
    };

    return {
        aktionNr: values.reduce((a, v) => reducer(a, v, value => value.length === 4), ''),
        // Assumption: everything that does not have a length of 4 is a cooperation partner
        kooperationsPartnerId: values.reduce((a, v) => reducer(a, v, value => Boolean(value && value.length !== 4)), '')
    };
};

const mapToStaticValues = (aemConfig: AemConfig, aktionNrCookie: string, queryString: string, fremdId: string | undefined): IStaticValues => {
    // tslint:disable-next-line:no-any
    const qsParams: any = qs.parse(queryString, {ignoreQueryPrefix: true});

    const agentur = qsParams && qsParams.adh_oenr
        ? {
            nr: qsParams.adh_oenr.substr(1, 5),
            personnr: qsParams.adh_oenr.substr(6),
            art: qsParams.adh_oenr.substr(0, 1),
            vorname: qsParams.adh_vorname,
            nachname: qsParams.adh_nachname,
            anrede: qsParams.adh_anrede,
            strasse: qsParams.adh_strasse,
            plz: qsParams.adh_plz,
            ort: qsParams.adh_ort,
            tel: qsParams.adh_tel,
            fax: qsParams.adh_fax,
            mobil: qsParams.adh_mobil,
            mail: qsParams.adh_mail,
            internet: qsParams.adh_internet
        }
        : undefined;

    const splittedCookieValue = getSplittedCookieValue(aktionNrCookie, agentur !== undefined);

    const kooperationsPartner: IKooperationsPartner | undefined = splittedCookieValue.kooperationsPartnerId
        ? { id: splittedCookieValue.kooperationsPartnerId, fremdId }
        : undefined;

    return {
        aktionsnummer: splittedCookieValue.aktionNr,
        kooperationsPartner,
        theme: aemConfig.theme ? aemConfig.theme as THEME : THEME.ergodirekt,
        dtmPageNames: {
            pageTarifdaten: aemConfig.dtmBasisdaten,
            pageBeitrag: aemConfig.dtmBeitrag,
            pageDuw: aemConfig.dtmDuw,
            pageBeitragAktualisiert: aemConfig.dtmBeitragNeu,
            pagePersoenliches: aemConfig.dtmPersonendaten,
            pageAbschluss: aemConfig.dtmPruefenSenden,
            pageFeedback: aemConfig.dtmFeedback
        },
        trackingPageNames: {
            pageTarifdaten: aemConfig.trackingNameBasisdaten,
            pageBeitrag: aemConfig.trackingNameBeitrag,
            pageDuw: aemConfig.trackingNameGesundheitsfragenDUW,
            pageBeitragInclRisiko: aemConfig.trackingNameBerechnungsergebnisNachDUW,
            pagePersoenliches: aemConfig.trackingNamePersonendaten,
            pagePruefenUndSenden: aemConfig.trackingNamePruefenUndSenden,
            pageFeedbackAg: aemConfig.trackingNameFeedbackAngebot,
            pageFeedbackAbschluss: aemConfig.trackingNameFeedbackAbschluss,
            pageFeedbackAblehnung: aemConfig.trackingNameGesundheitsfragenNegativ,
            pageFeedbackAntrag: aemConfig.trackingNameAntragFeedback
        },
        documents: {
            vorvertraglicheAnzeigepflicht: aemConfig.vorvertraglicheAnzeigepflicht
        },
        promotion: aemConfig && aemConfig.isPromotionCodeEnabled === 'true'
            ? {
                text: aemConfig.promotionCodeText as string,
                label: aemConfig.promotionCodeLabel as string,
                imageSource: aemConfig.promotionCodeImage as string,
                footnoteUrl: aemConfig.promotionCodeFootnoteUrl as string
            }
            : undefined,
        agentur,
        betreuungsweicheOn: aemConfig.betreuungsweicheOn === 'true',
    };
};

export default mapToStaticValues;
