import * as React from 'react';
import Navigation from '../Navigation';
import { StepButton } from '@eg/elements/StepButton';
import { Steps, Versandart, Zahlweise } from '../../consts';
import childAppStatus from '../../state/model/childAppStatus';
import { LoadingSpinner } from '@eg/elements/LoadingSpinner';
import { ICheckoutMessaging } from '../../messaging/checkout/checkoutMessaging';
import * as moment from 'moment';
import Tarifvariante from '../../state/model/Tarifvariante';
import DefaultButtonZurueck from '../DefaultButtonZurueck';
import { IPersonVp } from '../../state/model/IPersonVp';
import { IPersonVn } from '../../state/model/IPersonVn';
import { IDuwMessaging } from '../../messaging/duw/duwMessaging';
import { THEME } from '@eg/elements/components/Provider';

enum PdfDocumentStatus {
    INITIAL,
    FETCHING,
    DOWNLOADED
}

interface ICheckoutState {
    isIddErrorMessageDisplayed: boolean;
    isValid: boolean;
    isSubmitButtonLoading: boolean;
    documentStatus: PdfDocumentStatus;
}

export interface ICheckoutProps {
    theme: THEME;
    apiRootUrl: string;
    apiDuwRootUrl: string;
    businessId: string;
    checkoutAppLoadingState: childAppStatus;
    duwMessaging: IDuwMessaging;
    checkoutMessaging: ICheckoutMessaging;
    isAgentur: boolean;
    insuree: IPersonVn;
    insuredPerson: IPersonVp | undefined;
    zahlweise: Zahlweise;
    versicherungsbeginn: moment.Moment;
    onNavigate: (newStep: number) => void;
    beitrag: number;
    onCompleteOffer: (dispatch: Versandart) => void;
    tarifVariante: Tarifvariante;
    token: string;
    onError: (e: Error) => void;
    tracking: () => void;
    downloadDocument: (finishCallback: () => void) => void;
}

class Checkout extends React.Component<ICheckoutProps, ICheckoutState> {

    private readonly duwHeadline = 'Fragebogen';
    private readonly duwMountingPoint = 'root-duw';

    constructor(props: ICheckoutProps, state: ICheckoutState) {
        super(props, state);

        this.state = {
            isIddErrorMessageDisplayed: false,
            isValid: false,
            isSubmitButtonLoading: false,
            documentStatus: PdfDocumentStatus.INITIAL,
        };
    }
    onDownloadButtonClick = () => {
        this.setState( { documentStatus: PdfDocumentStatus.FETCHING } );
        this.props.downloadDocument(() => {
            this.setState( { documentStatus: PdfDocumentStatus.DOWNLOADED } );
        });
    }
    dispatchRenderCheckout = () => {
        this.props.checkoutMessaging.dispatchRenderCheckout(
            this.props.theme,
          {
                downloadButtonLoading: this.state.documentStatus === PdfDocumentStatus.FETCHING,
                downloadButtonDisabled: false,
                onDownloadButtonClick: this.onDownloadButtonClick
            },
            this.props.businessId,
            this.props.insuree,
            this.props.isAgentur,
            this.props.beitrag,
            this.props.zahlweise,
            this.props.versicherungsbeginn,
            this.props.onNavigate,
            this.state.isIddErrorMessageDisplayed,
            {
                onRenderComplete: this.dispatchRenderDuw,
                onError: this.props.onError,
                onReportValidity: this.handleCheckoutValidated
            },
            this.props.tarifVariante,
            this.duwHeadline,
            this.duwMountingPoint,
            this.props.apiRootUrl,
            this.props.insuredPerson,
        );
    };

    dispatchRenderDuw = () => this.props.duwMessaging.dispatchRenderReadonlyMode(
        this.props.token,
        this.props.theme,
        this.props.apiDuwRootUrl,
        this.props.onError,
        this.props.insuree);

    componentDidMount() {
        this.props.tracking();

        if (this.props.checkoutAppLoadingState === childAppStatus.loadingSuccessfullyFinished) {
            this.dispatchRenderCheckout();
        }
    }

    componentDidUpdate(prevProps: ICheckoutProps, prevState: ICheckoutState) {
        // handle case when loading was too slow
        if (prevProps.checkoutAppLoadingState === childAppStatus.loadingInProgress
            && this.props.checkoutAppLoadingState === childAppStatus.loadingSuccessfullyFinished) {
            this.dispatchRenderCheckout();
        }
        if (prevState.documentStatus !== this.state.documentStatus) {
            this.dispatchRenderCheckout();
        }
        if (!prevState.isIddErrorMessageDisplayed && this.state.isIddErrorMessageDisplayed) {
            this.props.checkoutMessaging.dispatchDisplayErrorMessages();
        }
    }

    handleCheckoutValidated = (isValid: boolean) => {
        this.setState({isValid});
    };

    handleSubmit = () => {
        if (!this.state.isValid) {
            this.setState({isIddErrorMessageDisplayed: true});
        } else {
            this.props.onCompleteOffer(Versandart.Online);
            this.setState({isSubmitButtonLoading: true});
        }
    };

    componentWillUnmount() {
        this.props.checkoutMessaging.dispatchUnmountCheckout();
    }

    render() {
        const result = this.props.checkoutAppLoadingState === childAppStatus.loadingSuccessfullyFinished
            ? (
                <>
                    <div data-component-id="checkout_app" id="root-checkout"/>
                    <br/>
                </>
            )
            : (
                <LoadingSpinner data-component-id="loading_spinner" show={true}/>
            );

        return (
            <>
                {result}

                <Navigation
                    ctaBack={<DefaultButtonZurueck navigate={() => this.props.onNavigate(Steps.Persoenliches)}/>}
                    ctaWeiter={
                        <StepButton
                            text={'Online abschließen'}
                            subText={'zahlungspflichtiger Vertrag'}
                            loading={this.state.isSubmitButtonLoading}
                            step={'2'}
                            disabled={!this.state.isValid || this.state.documentStatus !== PdfDocumentStatus.DOWNLOADED}
                            data-component-id="button_checkout"
                            onClick={this.handleSubmit}
                        />
                    }
                />
            </>
        );
    }
}

export default Checkout;
