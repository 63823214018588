import * as React from 'react';
import { Steps, Versandart, Zahlweise } from '../../consts';
import styled from 'styled-components';
import Tarifvariante from '../../state/model/Tarifvariante';
import { IStaticDtmPageNames } from '../../state/model/IStaticValues';
import { getAngebotsnr, getJahresbeitrag, getSeitenname, getSeitenpfad, getTarifmodus, getVertriebsprodukt } from './dtmMapper';

interface IDtmWidgetProps {
    angebotsnr?: string;
    tarifvariante?: Tarifvariante;
    beitrag: number|undefined;
    zahlweise: Zahlweise;
    step: Steps;
    pageNames: IStaticDtmPageNames;
    versandart?: Versandart;
}

const InvisibleArea = styled.div`
    display: none
`;

const DtmWidget = (props: IDtmWidgetProps) => {
    return (
        <InvisibleArea className="dtmWidget">
            <div className="DataElementVertriebsprodukt">{getVertriebsprodukt(props.tarifvariante)}</div>
            <div className="DataElementTarifmodus">{getTarifmodus(props.step, props.versandart)}</div>
            <div className="DataElementBeitrag">{getJahresbeitrag(props.beitrag, props.zahlweise)}</div>
            <div className="DataElementOrderId">{getAngebotsnr(props.angebotsnr)}</div>
            <div className="DataElementSeitenPfad">{getSeitenpfad()}</div>
            <div className="DataElementSeitenName">{getSeitenname(props.step, props.pageNames)}</div>
        </InvisibleArea>
    );
};

export default DtmWidget;
