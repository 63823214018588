import * as React from 'react';

interface IAnnotationContainerProps {
    annotations: string[];
}

const AnnotationContainer: React.StatelessComponent<IAnnotationContainerProps> = props => {
    return (
        <ol className="ee_annotation-container">
            {props.annotations
                .filter(a => a !== undefined)
                .map((annotation: string, index: number) => {
                    let asterix = '*';
                    for (let i = 0; i < index; i++) {
                        asterix += '*';
                    }

                    return (
                        <li className="ee_annotation-container__annotation" key={`astx-${index}`}>
                        <span className="ee_annotation-container__counter">
                            {asterix}
                        </span>
                            <span className="ee_annotation-container__text">
                            {annotation}
                        </span>
                        </li>
                    );
                })}
        </ol>
    );
};

export default AnnotationContainer;
