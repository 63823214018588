import * as React from 'react';
import Button from '@eg/elements/Button';
import styled from 'styled-components';

interface IPreContractualInformationProps {
    pdfLocation: string;
}

const PreContractualInformation = (props: IPreContractualInformationProps) => {
    const Paragraph = styled.p`
        margin-bottom: 15px 
    `;

    const linkStyle = {'padding': '0'};

    return (
        <div>
            <b>Online-Abschluss mit Gesundheitsprüfung</b>
            <Paragraph>Bitte halten Sie dazu ggf. Unterlagen von Ihrem Arzt oder Krankenhaus bereit, wenn vorhanden.</Paragraph>
            <Paragraph>
                Sie werden nun Schritt für Schritt durch die Gesundheitsfragen geführt, die Sie bitte jeweils mit „ja“ oder „nein“ beantworten, ggf.
                sind weitere Angaben nötig. Die Anzahl der Fragen ist abhängig von Ihrem Gesundheitszustand und anderen Faktoren, bitte nehmen Sie sich
                etwas Zeit dafür und antworten Sie nach bestem Wissen. Im Normalfall dauert das weniger als zehn Minuten.
            </Paragraph>

            <b>Sie erfahren abschließend ob ein Risikozuschlag für Ihren Beitrag anfällt.&nbsp;</b>
            Danach können Sie den Tarif online beantragen und erhalten Ihre Versicherungsunterlagen zugeschickt.
            Bitte beantworten Sie für einen Online-Abschluss die folgenden Fragen wahrheitsgemäß und vollständig und beachten Sie die Hinweise zur&nbsp;
            <a href={props.pdfLocation} target="_blank" rel="noopener noreferrer">
                <Button variant="text-link" style={linkStyle}>vorvertraglichen Anzeigepflicht (PDF)</Button>
            </a>.

        </div>
    );
};

export default PreContractualInformation;
