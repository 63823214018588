import { IAppState } from '../state/model/AppState';
import { IStaticTrackingPageNames } from '../state/model/IStaticValues';
import { GlobalTariffTrackingElements } from './GlobalTariffTrackingElements';
import TrackingEvents from './TrackingEvents';
import * as mapper from './trackingMapper';
import { getGewaehlteVariante } from '../state/helperFunctions';
import EventBasedTarifTrackingElements from './EventBasedTrackingElements';
import { Tracker } from '@eg/edtrackingmodule';
import { Versandart } from '../consts';
import moment from 'moment';
import Tarifvariante from '../state/model/Tarifvariante';

export default class TariffTracker {

    private firstCalculation: boolean = true;

    private readonly pageNames: IStaticTrackingPageNames;
    private tracker: Tracker;

    constructor(pageNames: IStaticTrackingPageNames) {
        this.pageNames = pageNames;
        this.tracker = new Tracker('SZSSZL_GLOBAL_IDENTIFIER', GlobalTariffTrackingElements);

        if(window.aemVarsToDatalayer) {
            window.aemVarsToDatalayer.City = "user[0].profile.address.city";
            window.aemVarsToDatalayer.FirstLineAddress = "user[0].profile.address.line1";
            window.aemVarsToDatalayer.Name = "user[0].profile.profileInfo.firstName";
            window.aemVarsToDatalayer.EmailAddress = "user[0].profile.profileInfo.email";
            window.aemVarsToDatalayer.PhoneNumber = "user[0].profile.profileInfo.telephone";
            window.aemVarsToDatalayer.PhoneNumberE164 = "user[0].profile.profileInfo.telephoneE164";
            window.aemVarsToDatalayer.LastName = "user[0].profile.profileInfo.lastName";
            window.aemVarsToDatalayer.PostalCode = "user[0].profile.address.postalCode";
        }
    }

    trackBasicData(state: IAppState) {
        if (this.shouldTrack()) {
            this.mergeEventData(state);
            this.tracker.track(TrackingEvents.antragStart);
        }
    }

    trackDuePage(state: IAppState) {
        if (this.shouldTrack()) {
            this.mergeEventData(state);
            this.tracker.merge({
                VnVp: mapper.isInsureeEqualWithInsuredPerson(state.angebot.personenDaten),
                TarifOptionen1: mapper.getTariffOption(state, !this.firstCalculation),
                VersicherungsBeginn: mapper.getDateOfInsuranceStart(state),
                Geburtsjahr: mapper.getYearOfBirth(state.angebot.personenDaten.vn.geburtsdatum as moment.Moment),
            });

            if (state.angebot.personenDaten.vp) {
                this.tracker.merge({
                    GeburtsjahrVp: mapper.getYearOfBirth(state.angebot.personenDaten.vp.geburtsdatum as moment.Moment)
                });
            }

            if (this.firstCalculation) {
                this.tracker.track(TrackingEvents.firstCalculation);
                this.firstCalculation = false;

            } else {
                this.tracker.track(TrackingEvents.furtherCalculations);
            }
        }
    }

    trackDuwPage(state: IAppState) {
        if (this.shouldTrack()) {
            this.mergeEventData(state);
            this.tracker.track(); // track page call without events
        }
    }

    trackBeitragInclRisiko(state: IAppState) {
        if (this.shouldTrack()) {
            this.mergeEventData(state);
            this.tracker.track(); // track page call without events#
        }
    }

    trackPersonalDataPage(state: IAppState) {
        if (this.shouldTrack()) {
            this.mergeEventData(state);
            this.tracker.merge({
                BerechnungsErgebnis: mapper.getAnnualContribution(state),
                TarifOptionen1: mapper.getTariffOption(state, true),
            });
            this.tracker.track(TrackingEvents.personalData);
        }
    }

    trackCheckout(state: IAppState) {
        if (this.shouldTrack()) {
            this.mergeEventData(state);
            if(this.isPersonalDataComplete(state)) {
                this.trackPersonalData(state);
            }
            this.tracker.track(TrackingEvents.pruefenUndSenden);
        }
    }

    trackFeedbackPage(state: IAppState) {
        if (this.shouldTrack()) {
            this.mergeEventData(state);
            if(this.isPersonalDataComplete(state)) {
                this.trackPersonalData(state);
            }
            // tslint:disable-next-line
            let event: any;

            switch (state.angebot.versandart) {
                case Versandart.Email:
                    event = TrackingEvents.offerViaEmail;
                    break;

                case Versandart.Postalisch:
                    event = TrackingEvents.offerViaPost;
                    break;

                case Versandart.Online:
                    event = TrackingEvents.antragAbschluss;
                    break;

                default:
                    break;
            }

            if (event) {
                this.tracker.track(event);
            } else {
                this.tracker.track();
            }
        }
    }

    private mergeEventData(state: IAppState) {
        if (this.shouldTrack()) {
            this.tracker.prepareEventBasedElementsType(EventBasedTarifTrackingElements);

            const trackingObject = {
                ViewName: mapper.getTrackingViewName(state, this.pageNames),
                NavigationsPfad: mapper.getNavigation(state, this.pageNames, window.location.toString()),
                Produkt: mapper.getTrackingProduct(state),
                Tarifkurzbezeichnung: mapper.getShortname(getGewaehlteVariante(state) as Tarifvariante)
            };

            this.tracker.merge(trackingObject);
        }
    }

    private shouldTrack = () => {
        return window.OptanonActiveGroups ? window.OptanonActiveGroups.includes(',C0002,') : false;
    };

    private trackPersonalData = (state: IAppState) => {
        const personalData: { [key: string]: string | undefined } = {
            Name: mapper.getName(state),
            LastName: mapper.getLastName(state),
            EmailAddress: mapper.getEmailAddress(state),
            City: mapper.getCity(state),
            FirstLineAddress: mapper.getFirstLineAddress(state),
            PhoneNumber: mapper.getPhoneNumber(state),
            PhoneNumberE164: mapper.getPhoneNumberE164(state),
            PostalCode: mapper.getPostalCode(state),
        };

        // Remove undefined values from the tracking object
        Object.keys(personalData).forEach(key => !personalData[key] && delete personalData[key]);

        this.tracker.merge(personalData);
    }

    private isPersonalDataComplete = (state: IAppState) => {
        return state.personenDatenMeta.personendatenFuerAngebotKomplett;
    }
}
