import { AgentWindow, ChannelType, TrackingEvents } from '../../types/Agency';

export const DEFAULT_ACTION_NUMBER_FOR_AGENT_PAGES = '8565';
export const DEFAULT_ACTION_NUMBER_FOR_ERGO_DE_PAGES = '8504';
export const DEFAULT_OE_NUMBER_FOR_ERGO_DE_PAGES = '';

export const ACTION_NUMBER_COOKIE_KEY: string = 'aktionNr';
export const OE_NUMBER_QUERY_KEY: string = 'adh_oenr';
export const MARKETING_ACTION_NUMBER_KEY: string = 'actionNumber';
export const MARKETING_AGENT_NUMBER_KEY: string = 'agentNumber';
export const MARKETING_SALES_COOKIES_KEY: string = 'salesCookies';
export const APP_EVENT_DATA_KEY: string = 'appEventData';

const AKTIONSNUMMER_COOKIENAME: string = 'aktionNr=';
const AKTIONSNUMMER_INDEX = 0;
const VERMITTLERNUMMER_INDEX = 1;

export interface IAgencyParams {
    oeNummer: string;
    vermittlernummer: string;
    aktionsnummer: string;
    channelType: ChannelType;
}

export const decodeAktionNrCookie = (index: number = 0): string => {
    const decodedCookieString = decodeURIComponent(document.cookie);
    const cookieList: string[] = decodedCookieString.split(';');
    const aktionsNummerCookie: string | undefined = cookieList.find((element) =>
        element.includes(AKTIONSNUMMER_COOKIENAME)
    );
    if (aktionsNummerCookie) {
        const aktionsNummernListe: string | undefined = aktionsNummerCookie.split('=')[1];
        if (aktionsNummernListe) {
            return aktionsNummernListe.split(',')[index];
        }
    }
    return '';
};

export const extractAktionsNummerFromCookie = (): string => {
    return decodeAktionNrCookie(AKTIONSNUMMER_INDEX);
};

export const extractVermittlerNummerFromCookie = (): string => {
    return decodeAktionNrCookie(VERMITTLERNUMMER_INDEX);
};

export const getOenrFromQueryParam = (): string | null => {
    const searchString = window.location.search;
    if (searchString) {
        const params = new URLSearchParams(searchString);
        const oeNummer = params.get(OE_NUMBER_QUERY_KEY);
        return oeNummer ? oeNummer : null;
    }
    return null;
};

export const getOeNumberFromAdhopPages = (): string | null => {
    const { agencyData } = window as AgentWindow;
    return agencyData && agencyData.oenrNumber ? agencyData.oenrNumber : null;
};

export const getMarketingEventProperty = (property: string): string | null => {
    const appEventData = (window as any)[APP_EVENT_DATA_KEY] as Array<{ event: string } & { [key: string]: any }>;

    if (appEventData) {
        const marketingEvent = appEventData.find((e) => e.event === TrackingEvents.MARKETING);
        if (marketingEvent && marketingEvent.miscellaneous && marketingEvent.miscellaneous.marketing) {
            return marketingEvent.miscellaneous.marketing[property] || null;
        } else {
            return null;
        }
    }
    return null;
};

export const getActionNumberFromMarketingEvent = (): string | null => {
    return getMarketingEventProperty(MARKETING_ACTION_NUMBER_KEY);
};

export const getAgentNumberFromMarketingEvent = (): string | null => {
    return getMarketingEventProperty(MARKETING_AGENT_NUMBER_KEY);
};

export const getActionNumberFromGlobalVariable = (): string | null => {
    const aktionNumberValueFromGlobalVariable = (window as any).defaultAktionNr || '';
    const [extractedActualActionNumber] = aktionNumberValueFromGlobalVariable.split(',');
    if (extractedActualActionNumber) {
        return extractedActualActionNumber;
    }
    return null;
};

export const getActionNumber = (): string => {
    const marketingActionNumber = getActionNumberFromMarketingEvent();
    if (marketingActionNumber) {
        return marketingActionNumber;
    }
    const cookiesActionNumber = extractAktionsNummerFromCookie();
    if (cookiesActionNumber) {
        return cookiesActionNumber;
    }

    const actionNumberFromGlobalVariable = getActionNumberFromGlobalVariable();
    if (actionNumberFromGlobalVariable) {
        return actionNumberFromGlobalVariable;
    }

    if (isAdhopPage() || isSitecorePage()) {
        return DEFAULT_ACTION_NUMBER_FOR_AGENT_PAGES;
    }
    return DEFAULT_ACTION_NUMBER_FOR_ERGO_DE_PAGES;
};

export const getChannelType = (): ChannelType => {
    if (isPartnerCooperationPage()) {
        return ChannelType.COOP_SALES_PARTNER;
    } else if (isAdhopPage() || isSitecorePage()) {
        return ChannelType.AGENT_BROKER;
    } else {
        return ChannelType.ERGO_PAGE;
    }
};

export const getOeNumber = (): string => {
    if (getChannelType() === ChannelType.ERGO_PAGE) {
        return DEFAULT_OE_NUMBER_FOR_ERGO_DE_PAGES;
    } else {
        return getOenrFromQueryParam() || getOeNumberFromAdhopPages() || '';
    }
};

export const isPartnerCooperationPage = (): boolean => {
    return !!getMarketingEventProperty(MARKETING_SALES_COOKIES_KEY);
};
export const isAdhopPage = (): boolean => !!getOeNumberFromAdhopPages();
export const isSitecorePage = (): boolean => !!getOenrFromQueryParam();

export const getAgencyParams = (): IAgencyParams => {
    const oeNummer = getOeNumber();
    const vermittlernummer = getAgentNumberFromMarketingEvent() || extractVermittlerNummerFromCookie();
    const aktionsnummer = getActionNumber();
    const channelType = getChannelType();

    return {
        oeNummer,
        vermittlernummer,
        aktionsnummer,
        channelType
    };
};
