import { Steps, uiDateFormat, Versandart, Zahlweise } from '../consts';
import { IStaticTrackingPageNames } from '../state/model/IStaticValues';
import Tarifvariante from '../state/model/Tarifvariante';
import { IAppState } from '../state/model/AppState';
import { getBetrag, getGewaehlteVariante, getSelectedPricingModel, getSelectedInsuranceStart, isInsureeEqualToInsuredPerson } from '../state/helperFunctions';
import * as moment from 'moment';
import Geschlecht from '../state/model/Geschlecht';
import { DuwErgebnis } from '../infrastructure/model/DuwErgebnis';
import { IPersonendaten } from '../state/model/IPersonendaten';
import {
    PHONE_NUMBER_PREFIXES,
    preparePhoneNumberForHashing,
    hashData,
} from '@b2c-commons/utils';

const getShortname = (tariffVariant: Tarifvariante): string => {
    if (tariffVariant === Tarifvariante.SZS) {
        return 'szs';
    } else if (tariffVariant === Tarifvariante.SZL) {
        return 'szl';
    }
    return 'szsszl';
};

const getTariffIdentifier = () => 'Kranken-Zusatz-Versicherung';

const getTrackingViewName = (appState: IAppState, aemValues: IStaticTrackingPageNames): string => {
    if (!aemValues) {
        return '';
    }

    let foundViewName: string = '';
    switch (appState.indexOfActiveStep) {
        case Steps.Tarifdaten:
            foundViewName = aemValues.pageTarifdaten || '';
            break;
        case Steps.BeitragVorlaeufig:
            foundViewName = aemValues.pageBeitrag || '';
            break;
        case Steps.Gesundheitsfragen:
            foundViewName = aemValues.pageDuw || '';
            break;
        case Steps.BeitragAktualisiert:
            foundViewName = aemValues.pageBeitragInclRisiko ||  '';
            break;
        case Steps.Persoenliches:
            foundViewName = aemValues.pagePersoenliches || '';
            break;
        case Steps.Checkout:
            foundViewName = aemValues.pagePruefenUndSenden || '';
            break;
        case Steps.Feedback:
            if (appState.angebot.versandart === Versandart.Email || appState.angebot.versandart === Versandart.Postalisch) {
                foundViewName = aemValues.pageFeedbackAg || '';
            } else {
                if (appState.angebot.risiko) {
                    switch (appState.angebot.risiko.duwErgebnis) {
                        case DuwErgebnis.MANUELLE_RISIKOPRUEFUNG:
                            foundViewName = aemValues.pageFeedbackAntrag || '';
                            break;

                        case DuwErgebnis.ABLEHNUNG:
                            foundViewName = aemValues.pageFeedbackAblehnung || '';
                            break;

                        case DuwErgebnis.ANNAHME:
                            foundViewName = aemValues.pageFeedbackAbschluss || '';
                            break;

                        default:
                            break;
                    }
                }
            }

            break;
        default: 
            break;
    }

    return `${getShortname(getGewaehlteVariante(appState) as Tarifvariante)}:${foundViewName}`;
};

const getAnnualContribution = (appState: IAppState) => {
    if (!getBetrag(appState)) {
        return '0';
    }

    const contributionByTariff: number = getBetrag(appState) as number;
    let result: number;

    switch (getSelectedPricingModel(appState)) {
        case Zahlweise.Monatlich:       result = contributionByTariff * 12;   break;
        case Zahlweise.Vierteljahrlich: result = contributionByTariff * 4;    break;
        case Zahlweise.Halbjahrlich:    result = contributionByTariff * 2;    break;
        case Zahlweise.Jahrlich:        result = contributionByTariff;        break;
        default: result = 0;
    }

    return result.toFixed(2);
};

const getTrackingProduct = (appState: IAppState): string => {
    return `[${getTariffIdentifier()}];[${getShortname(getGewaehlteVariante(appState) as Tarifvariante)}];1;${getAnnualContribution(appState)}`;
};

const getNavigation = (appState: IAppState, aemValues: IStaticTrackingPageNames, path: string) => {
    if (!path) {
        return '';
    }

    const result = path
        .substring(0,  path.lastIndexOf('/'))
        .replace('https://', '')
        .replace('http://', '')
        .replace('/abschluss.html', '');

    return `${result}/${getTrackingViewName(appState, aemValues)}`;
};

const getTariffOption = (appState: IAppState, notFirstTimeOnContributionPage: boolean) => {
    const paymentOption = getSelectedPricingModel(appState);

    const options = [
        { zw: Zahlweise.Monatlich, mapped: '1/12' },
        { zw: Zahlweise.Vierteljahrlich, mapped: '1/4' },
        { zw: Zahlweise.Halbjahrlich, mapped: '1/2' },
        { zw: Zahlweise.Jahrlich, mapped: '1/1' },
    ];

    const foundPaymentMethod = options.find(x => x.zw === paymentOption);
    return foundPaymentMethod && notFirstTimeOnContributionPage ? `Rechner=szsszl | Zahlung=${foundPaymentMethod.mapped}` : 'Rechner=szsszl';
};

const isInsureeEqualWithInsuredPerson = (personenDaten: IPersonendaten) => {
    return isInsureeEqualToInsuredPerson(personenDaten) ? 'ja' : 'nein';
};

const getDateOfInsuranceStart = (appState: IAppState) => {
    const selectedInsuranceStart: moment.Moment = getSelectedInsuranceStart(appState.angebot.insuranceStartDates) as moment.Moment;
    return selectedInsuranceStart ? selectedInsuranceStart.format(uiDateFormat) : '';
};

const getYearOfBirth = (dateOfBirth: moment.Moment) => {
    return dateOfBirth ? dateOfBirth.format('YYYY') : '';
};

const getPostalCode = (appState: IAppState) => {
    return appState.angebot.personenDaten.vn.adresse?.plz || '';
};

const getGender = (appState: IAppState): string => {
    const gender = appState.angebot.personenDaten.vn.geschlecht;

    if (gender === Geschlecht.MAENNLICH) {
        return 'Herr';
    } else if (gender === Geschlecht.WEIBLICH) {
        return 'Frau';
    }

    return '';
};

const getName = (appState: IAppState): string => {
    return hashData(appState.angebot.personenDaten.vn.vorname || '');
}

const getLastName = (appState: IAppState): string => {
    return hashData(appState.angebot.personenDaten.vn.nachname || '');
}

const getEmailAddress = (appState: IAppState): string => {
    return hashData(appState.angebot.personenDaten.vn.email || '');
}

const getCity = (appState: IAppState): string => {
    return hashData(appState.angebot.personenDaten.vn.adresse?.ort || '');
}

const getFirstLineAddress = (appState: IAppState): string => {
    const street = appState.angebot.personenDaten.vn.adresse?.strasse;
    const houseNumber = appState.angebot.personenDaten.vn.adresse?.hausnummer;
    return hashData(`${street} ${houseNumber}`);
}

const getFullPhoneNumber = (appState: IAppState): string => {
    const phoneNumberStart = appState.angebot.personenDaten.vn.vorwahl;
    const phoneNumberEnd = appState.angebot.personenDaten.vn.rufnummer;
    const fullPhoneNumber = phoneNumberStart && phoneNumberEnd && preparePhoneNumberForHashing(`${phoneNumberStart}${phoneNumberEnd}`);
    return fullPhoneNumber || '';
}

const getPhoneNumber = (appState: IAppState): string | undefined => {
    const fullPhoneNumber = getFullPhoneNumber(appState);

    if (!fullPhoneNumber) {
        return undefined;
    }

    return hashData(`${PHONE_NUMBER_PREFIXES.german}${fullPhoneNumber}`);
}

const getPhoneNumberE164 = (appState: IAppState): string | undefined => {
    const fullPhoneNumber = getFullPhoneNumber(appState);

    if (!fullPhoneNumber) {
        return undefined;
    }

    return hashData(`${PHONE_NUMBER_PREFIXES.e164}${fullPhoneNumber}`);
}

export {
    getYearOfBirth,
    getGender,
    getAnnualContribution,
    getShortname,
    getNavigation,
    getPostalCode,
    getTariffOption,
    getTrackingViewName,
    getTrackingProduct,
    getDateOfInsuranceStart,
    isInsureeEqualWithInsuredPerson,
    getName,
    getLastName,
    getEmailAddress,
    getCity,
    getFirstLineAddress,
    getPhoneNumber,
    getPhoneNumberE164
};
