import * as React from 'react';
import Newsletter from './Newsletter';
import { Versandart } from '../../consts';

interface IOfferTextProps {
    formOfAddress: string;
    contactFormLink: JSX.Element;
    versandart: Versandart;
}

const getDynamicText = (dispatch: Versandart) => {
    switch (dispatch) {
        case Versandart.Email:
            return 'Sie bekommen es als PDF an Ihre E-Mail-Adresse.';
        case Versandart.Postalisch:
            return 'In wenigen Tagen bekommen Sie Ihr persönliches Angebot per Post.';
        default:
            return '';
    }
};

const OfferText: React.StatelessComponent<IOfferTextProps> = (props) => {
    return (
        <>
            <b>{props.formOfAddress}</b><br/><br/>
            Sie interessieren sich für eine unserer Versicherungen – das freut uns. Jetzt können auch Sie sich freuen:
            auf Ihr persönliches Angebot für eine richtig gute Absicherung. {getDynamicText(props.versandart)}
            <br/><br/>

            Fragen? Dann rufen Sie einfach unseren gebührenfreien Beratungs-Service an. Oder senden Sie uns eine {props.contactFormLink}.<br/><br/>

            <Newsletter />

        </>
    );
};

export default OfferText;
