import { IConsent } from './messaging/checkout/model/ICheckoutInput';

export enum Steps {
    Tarifdaten, // Tariff data
    BeitragVorlaeufig, // Contribution
    Gesundheitsfragen, // Health issues
    BeitragAktualisiert, // PostUpdated
    Persoenliches, // Personal
    Checkout,
    Feedback,
    ErrorSwitchToOffer
}

export enum Versandart {
    Email = 'EMAIL',
    Postalisch = 'POST',
    Online = 'ONLINE'
}

export enum Zahlweise {
    Jahrlich = 'JAEHRLICH',
    Halbjahrlich = 'HALBJAEHRLICH',
    Vierteljahrlich = 'VIERTELJAEHRLICH',
    Monatlich = 'MONATLICH'
}

export const duwConsent: IConsent = {
    title: 'Einwilligung zur Erhebung von Gesundheitsdaten',
    text: 'Ich willige ein, dass meine Gesundheitsdaten für die Erstellung und Bearbeitung meines Versicherungs-Antrags in den Systemen der ' +
        'ERGO gespeichert und genutzt werden dürfen. Sofern kein Vertrag zustande kommt, werden meine Gesundheitsdaten ' +
        'spätestens nach drei Jahren gelöscht.',
    errorMsg: 'Bitte bestätigen Sie, dass wir Ihre gesundheitsbezogenen Daten speichern und verarbeiten dürfen.'
};

export const uiDateFormat = 'DD.MM.YYYY';

export const szsAnnotation = `Der Beitrag wird planmäßig nach Altersgruppen erhöht.
                        Den Beitragsverlauf entnehmen Sie bitte der Infobox.
                        Der Tarif endet mit Vollendung des 66. Lebensjahres und wird dann auf den Tarif SZL mit stabilem Beitrag umgestellt.
                        Hier ist der dann gültige Beitrag des 66-Jährigen fällig.`;

export const vorlaeufigAnnotation = `Der hier errechnete Beitrag ist vorläufig. Wenn Sie die Gesundheitsfragen
                beantwortet haben, findet eine Risikoprüfung statt. Dabei können zusätzlich Risikozuschläge anfallen,
                z. B. bei relevanten Vorerkrankungen. Ist das zu versichernde Risiko zu hoch, kann Ihr Antrag
                abgelehnt werden.`;
