import * as React from 'react';

interface IStepProps {
    isActiveStep: boolean;
}

const Step: React.StatelessComponent<IStepProps> = props => {
    if (!props.isActiveStep) {
        return null;
    }

    return <>{props.children}</>;
};

export default Step;
