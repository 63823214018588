import { IAppState } from './model/AppState';
import childAppStatus from './model/childAppStatus';
import { IPersonendaten, IPersonendatenMeta } from './model/IPersonendaten'
import { Versandart, Zahlweise } from '../consts';
import { IVariante as IStateVariante } from './model/IVariante';
import * as moment from 'moment';
import { IPersonendatenChangedOutput } from '../messaging/personendatenerfassung/model/output/IPersonendatenChangedOutput';
import { IPersonVp } from './model/IPersonVp';
import { IPersonOutput } from '../messaging/personendatenerfassung/model/output/IPersonOutput';
import { getGewaehlteVariante, getSelectedPricingModel, isInsureeEqualToInsuredPerson } from './helperFunctions';
import TariffVariant from './model/Tarifvariante';
import { IInsuranceStart } from './model/IInsuranceStart';
import { IBasisData } from '../messaging/duw/model/IDuwInput';
import Geschlecht from './model/Geschlecht';
import IAngebot from './model/IAngebot';
import { IBusinessRulesValidation } from '../infrastructure/model/IValidation';
import { IBeitrag } from './model/IBeitrag';
import IPerson from './model/IPerson';

// can't add as normal import - open issue #2599 (https://github.com/lodash/lodash/issues/2599)
const cloneDeep = require('lodash.clonedeep');

const changeChildAppLoadingState = (state: IAppState, loadingState: childAppStatus, propertyToUpdate: string) => {
    return {
        ...state,
        [propertyToUpdate]: loadingState
    };
};

const navigate = (state: IAppState, step: number) => {
    return {
        ...state,
        indexOfActiveStep: step
    };
};

const onlineSalePreventingErrorOccured = (state: IAppState): IAppState => {
    return {
        ...state,
        angebot: {
            ...state.angebot,
            token: undefined,
            risiko: undefined
        },
        isOnlineAbschlussDefekt: true
    };
};

const switchTariffVariantButKeepPaymentMethod = (szs: IStateVariante, szl: IStateVariante, selectedTariffVariant: TariffVariant, paymentMethod: Zahlweise) => {
    const chosenTariffVariant = selectedTariffVariant === TariffVariant.SZS ? szs : szl;
    const unchosenTariffVariant = selectedTariffVariant === TariffVariant.SZS ? szl : szs;

    if (unchosenTariffVariant) {
        unchosenTariffVariant.beitraege.forEach(beitrag => beitrag.isSelected = false);
    }

    // find the contribution with the chosen payment method on the chosen tariff variant
    const contributionToPaymentMethod = chosenTariffVariant.beitraege.find(contribution => contribution.zahlweise === paymentMethod) as IBeitrag;
    contributionToPaymentMethod.isSelected = true;
};

const changeTariffVariant = (state: IAppState, selectedTariffVariant: TariffVariant): IAppState => {
    const szs: IStateVariante = cloneDeep(state.angebot.szs);
    const szl: IStateVariante = cloneDeep(state.angebot.szl);
    const offer = {...state.angebot, szs, szl};

    // save payment method temporarily
    const paymentMethod = getSelectedPricingModel(state);

    if (TariffVariant.SZS === selectedTariffVariant) {
        switchTariffVariantButKeepPaymentMethod(szs, szl, selectedTariffVariant, paymentMethod);
    }
    if (TariffVariant.SZL === selectedTariffVariant) {
        switchTariffVariantButKeepPaymentMethod(szs, szl, TariffVariant.SZL, paymentMethod);
    }

    return {
        ...state,
        angebot: offer
    };
};

const duwResetted = (state: IAppState) => {
    const angebot: IAngebot = {
        ...state.angebot,
        risiko: undefined,
        token: undefined
    };

    return {
        ...state,
        angebot
    };
};

const processProductBasics = (
    state: IAppState,
    insuranceStartDates: IInsuranceStart[],
    businessRules: IBusinessRulesValidation,
): IAppState => {
    return {
        ...state,
        isLoading: false,
        businessRules: {
            birthdayRules: {
                dateSpanMinForVn: businessRules.dateSpanMinForVn,
                dateSpanMaxForVn: businessRules.dateSpanMaxForVn,
                dateSpanMinForVp: businessRules.dateSpanMinForVp,
                dateSpanMaxForVp: businessRules.dateSpanMaxForVp
            },
            roomingInRules: {
                dateSpanMaxForVpRoomingIn: businessRules.dateSpanMaxForVpRoomingIn
            }
        },
        angebot: {
            ...state.angebot,
            insuranceStartDates
        }
    };
};

const basicDataSpecified = (state: IAppState, insuranceStart: moment.Moment, dateOfBirthInsuree: moment.Moment, dateOfBirthInsuredPerson: moment.Moment | undefined): IAppState => {

    const personalData: IPersonendaten = {
        ...state.angebot.personenDaten,
        vn: {
            ...state.angebot.personenDaten.vn,
            geburtsdatum: dateOfBirthInsuree
        },
        vp: dateOfBirthInsuredPerson ? {
                ...state.angebot.personenDaten.vp,
                geburtsdatum: dateOfBirthInsuredPerson,
            }
            : undefined
    };

    const insuranceStartDates: IInsuranceStart[] = state.angebot.insuranceStartDates.map(d => {
        const selected = d.insuranceStartDate.isSame(insuranceStart);

        return {
            insuranceStartDate: d.insuranceStartDate,
            selected
        };
    });

    return {
        ...state,
        angebot: {
            ...state.angebot,
            insuranceStartDates,
            personenDaten: personalData
        }
    };
};

const switchPaymentMethodOnTariffVariant = (tariffVariant: IStateVariante, paymentMethod: Zahlweise) => {
    tariffVariant.beitraege.forEach(contribution => contribution.isSelected = false);
    const newContribution = tariffVariant.beitraege.find(beitrag => beitrag.zahlweise === paymentMethod) as IBeitrag;
    newContribution.isSelected = true;
};

const zahlweiseGeaendert = (state: IAppState, paymentMethod: Zahlweise): IAppState => {
    const selectedTariffVariant = getGewaehlteVariante(state);

    const szs = cloneDeep(state.angebot.szs);
    const szl = cloneDeep(state.angebot.szl);

    if (selectedTariffVariant) {
        TariffVariant.SZS === selectedTariffVariant
            ? switchPaymentMethodOnTariffVariant(szs, paymentMethod)
            : switchPaymentMethodOnTariffVariant(szl, paymentMethod);
    }

    return {
        ...state,
        angebot: {
            ...state.angebot,
            szs,
            szl
        }
    };
};

const mapInsuredPersonFromPdeToStatePerson = (insuredPerson: IPersonOutput, dateOfBirth: moment.Moment): IPersonVp => {
    return {
        vorname: insuredPerson.vorname,
        nachname: insuredPerson.nachname,
        geburtsdatum: dateOfBirth,
        geschlecht: insuredPerson.geschlecht,
        adresse: insuredPerson.adresse,
        id: insuredPerson.id,
    };
};

const personalDataChanged = (state: IAppState, personendatenOutput: IPersonendatenChangedOutput): IAppState => {
    const personalData: IPersonendaten = {
        vn: {
            ...state.angebot.personenDaten.vn,
            ...personendatenOutput.vn
        },
        vp: personendatenOutput.vps.length === 1
            ? mapInsuredPersonFromPdeToStatePerson(personendatenOutput.vps[0], (state.angebot.personenDaten.vp as IPersonVp).geburtsdatum as moment.Moment)
            : undefined,
    };

    const personalDataMetaInformation: IPersonendatenMeta = {
        personendatenFuerOnlineAbschlussKomplett: personendatenOutput.isAbschlussMoeglich,
        personendatenFuerAngebotKomplett: personendatenOutput.isAngebotMoeglich
    };

    const offer = {...state.angebot, personenDaten: personalData};

    return {
        ...state,
        angebot: offer,
        personenDatenMeta: personalDataMetaInformation
    };
};

const changeDispatch = (state: IAppState, dispatch: Versandart): IAppState => {
    return {
        ...state,
        angebot: {
            ...state.angebot,
            versandart: dispatch
        }
    };
};

const tokenReceived = (state: IAppState, token: string, basicData: IBasisData): IAppState => {
    const newState: IAppState = cloneDeep(state);

    const person: IPerson = isInsureeEqualToInsuredPerson(state.angebot.personenDaten)
        ? newState.angebot.personenDaten.vn
        : newState.angebot.personenDaten.vp as IPerson

    newState.angebot.token = token;

    person.gewicht = basicData.gewicht;
    person.groesse = basicData.groesse;
    person.geschlecht = Geschlecht[basicData.geschlecht as keyof typeof Geschlecht];

    return newState;
};

const promotionFootenoteFetched = (state: IAppState, promotionFootnote: string): IAppState => {
    return {
        ...state,
        promotionFootnote
    };
};

export {
    basicDataSpecified,
    changeChildAppLoadingState,
    changeDispatch,
    zahlweiseGeaendert,
    changeTariffVariant,
    navigate,
    personalDataChanged,
    duwResetted,
    onlineSalePreventingErrorOccured,
    processProductBasics,
    tokenReceived,
    promotionFootenoteFetched
};
