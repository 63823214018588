import { IVariante as IStateVariante } from './model/IVariante';
import { IBeitrag, ITarifierung } from '../infrastructure/model/ITarifierung';
import { IAppState } from './model/AppState';
import { IBeitrag as IStateBeitrag } from './model/IBeitrag';
import { getSelectedPricingModel } from './helperFunctions';

// can't add as normal import - open issue #2599 (https://github.com/lodash/lodash/issues/2599)
const cloneDeep = require('lodash.clonedeep');

const addOrUpdateBeitraege = (beitraege: IStateBeitrag[], sourceBeitraege: IBeitrag[], hasRisiko: boolean) => {
    sourceBeitraege.forEach((sourceBeitrag: IBeitrag) => {
        const correspondingBeitrag = beitraege.find((stateBeitrag: IStateBeitrag) => stateBeitrag.zahlweise === sourceBeitrag.zahlweise);
        if (!correspondingBeitrag) {
            beitraege.push({
                zahlweise: sourceBeitrag.zahlweise,
                betrag: sourceBeitrag.betrag,
                isSelected: false
            });
        } else {
            const key = hasRisiko
                ? 'betragAktualisiert'
                : 'betrag';

            correspondingBeitrag[key] = sourceBeitrag.betrag;
        }
    });
};

export const resetBeitraege = (szs: IStateVariante, szl: IStateVariante): void => {
    if (szs?.beitraege) {
        szs.beitraege = szs.beitraege.filter(item => item.isSelected);
    }
    if (szl?.beitraege) {
        szl.beitraege = szl.beitraege.filter(item => item.isSelected);
    }
}
export const processCalculationResult = (state: IAppState, tarifierung: ITarifierung, resetBeitreage = false): IAppState => {
    const szs: IStateVariante = cloneDeep(state.angebot.szs);
    const szl: IStateVariante = cloneDeep(state.angebot.szl);
    if(resetBeitreage) {
        resetBeitraege(szs, szl);
    }
    if (tarifierung.szs) {
        addOrUpdateBeitraege(szs.beitraege, tarifierung.szs.beitraege, Boolean(tarifierung.risiko));
        szs.eintrittsalter = tarifierung.szs.eintrittsalter;
        szs.id = tarifierung.szs.id
    }
    addOrUpdateBeitraege(szl.beitraege, tarifierung.szl.beitraege, Boolean(tarifierung.risiko));
    szl.eintrittsalter = tarifierung.szl.eintrittsalter;
    szl.id = tarifierung.szl.id;

    if (!tarifierung.szs) {
        // delete any existing posts and
        // Erwinge Select the corresponding contribution in the SZL (it could be that an SZS contribution was previously selected)
        szs.beitraege = [];

        const zahlweise = getSelectedPricingModel(state);
        (szl.beitraege.find(b => b.zahlweise === zahlweise) as IStateBeitrag).isSelected = true;
    }

    const newState = {
        ...state,
        angebot: {
            ...state.angebot,
            angebotId: tarifierung.angebotId,
            szs,
            szl,
            risiko: tarifierung.risiko
        }
    }
    if(tarifierung.vn) {
        newState.angebot.personenDaten.vn = {
            ...newState.angebot.personenDaten.vn,
            id: tarifierung.vn.personId
        }
    }
    if(tarifierung.vp) {
        newState.angebot.personenDaten.vp = {
            ...newState.angebot.personenDaten.vp,
            id: tarifierung.vp.personId
        }
    }

    return newState;
};
