import * as React from 'react';
import { ChangeEvent } from 'react';
import { Button } from '@eg/elements/Button';
import { SelectRow } from '@eg/elements/SelectRow';
import { Checkbox } from "@eg/elements/Checkbox"
import { Radio } from '@eg/elements/Radio';
import { RadioGroupRow } from '@eg/elements/RadioGroupRow';
import Card, { CardProps } from '@eg/elements/Card';
import * as moment from 'moment';
import Navigation from '../Navigation';
import { uiDateFormat } from '../../consts';
import { IBusinessRules } from '../../state/model/IBusinessRules';
import styled from 'styled-components';
import DateInputRowWrapper from './DateInputRowWrapper';
import IAngebot from '../../state/model/IAngebot';
import { IInsuranceStart } from '../../state/model/IInsuranceStart';
import { getSelectedInsuranceStart } from '../../state/helperFunctions';
import IPromotion from '../../state/model/IPromotion';
import PromotionTeaserPortal from '../promotion/PromotionTeaserPortal';
import PromotionFootnoteContainer from '../promotion/PromotionFootnoteContainer';
import { hasGeburtstagsErrors, hasBirthdayErrors } from '../../businessRules/birthdayRules';
import TooltipIcon from '@eg/elements/TooltipIcon';
import Modal from '@eg/elements/Modal';
import LayoutBox from '@eg/elements/LayoutBox';
import { hasChangesForcingDuwReset } from '../../businessRules/duwRules';

export interface ITarifdatenProps {
    angebot: IAngebot;
    onSubmitTarifierung: (insuranceStart: moment.Moment, geburtsdatumVn: moment.Moment, geburtsdatumVp: moment.Moment | undefined, resetDuw: boolean) => void;
    businessRules?: IBusinessRules;
    tracking: () => void;
    promotion?: IPromotion;
    onFootnoteFetched: (content: string) => void;
    isAgentur?: boolean;
}

export interface ITarifdatenState {
    geburtsdatumVn: moment.Moment | undefined;
    geburtsdatumVp: moment.Moment | undefined;
    selectedInsuranceStart: moment.Moment;
    isVnDatepickerTouched: boolean;
    isVpDatepickerTouched: boolean;
    isSubmitButtonLoading: boolean;
    isVnGleichVp: boolean;
    isDuwWarningDisplayed: boolean;
    hintModelOpened: boolean;
    hintCheckboxAccepted: boolean;
}

const hintConfiguration: CardProps = {
    padding: 'auto',
    label: "Hinweis",
    chipColor: '#E87A16',
    accentColor: '#E87A16',
    labelColor: '#fff',
    alignLabel: 'left',
    style: {
        "boxShadow": 'none!important',
        "paddingBottom": '0px!important'
    }
};

const hintCopy: { [key: string]: string } = {
    mainTitle: `Sie möchten jemand anderen absichern, z.B. Ihre/n Partner/in?`,
    firstBeforeAction: `Dies ist nur möglich, wenn es sich dabei um (nahe) Angehörige handelt, da ansonsten Versicherungssteuerpflicht eintreten kann. Wer zu den`,
    modalOpenAction: ` (nahen) Angehörigen `,
    firstAfterAction: `zählt, ist gesetzlich geregelt, und zwar in § 7 des Pflegezeitgesetzes und in § 15 der Abgabenordnung.`,
    secondEntry: "Bei Fragen helfen wir Ihnen gerne telefonisch weiter.",
    checkBoxValue: "Ich bestätige, dass es sich bei den anderen Personen um (nahe) Angehörige im Sinne des Pflegezeitgesetzes bzw. der Abgabenordnung handelt."
}

const hintModalCopy: { [key: string]: string | string[] } = {
    mainTitle: '(Nahe) Angehörige und damit versicherbarer Personenkreis sind:',
    menuPoints: [
        "Großeltern, Eltern, Schwiegereltern, Stiefeltern",
        "Ehegatten, Lebenspartner, Partner einer eheähnlichen oder lebenspartnerschaftsähnlichen Gemeinschaft, Geschwister, Ehegatten der Geschwister und Geschwister der Ehegatten, Lebenspartner der Geschwister und Geschwister der Lebenspartner",
        "Kinder, Adoptiv- oder Pflegekinder, die Kinder, Adoptiv- oder Pflegekinder des Ehegatten oder Lebenspartners, Schwiegerkinder und Enkelkinder",
        "Verlobte",
        "Verwandte und Verschwägerte gerader Linie",
        "Kinder der Geschwister",
        "Geschwister der Eltern",
        "Personen, die durch ein auf längere Dauer angelegtes Pflegeverhältnis mit häuslicher Gemeinschaft wie Eltern und Kind miteinander verbunden sind (Pflegeeltern und Pflegekinder)."
    ],
    firstEntry: 'Die Auflösung einer Verlobung bzw. die Beendigung einer eheähnlichen oder lebenspartnerschaftsähnlichen Gemeinschaft nach Vertragsschluss kann dazu führen, dass Versicherungssteuerpflicht eintritt. Dies gilt ebenso bei Beendigung der häuslichen Gemeinschaft mit Pflegekindern, wenn mit diesen keine innere Verbundenheit/Beziehung mehr besteht.',
    secondEntry: 'Sie müssen uns in diesen Fällen umgehend informieren. Denn Versicherungen von Personen, die keine (nahen) Angehörigen sind, sind in der Regel versicherungssteuerpflichtig. Das bedeutet für Sie: Sie könnten dann zusätzlich zum vertraglich geschuldeten Beitrag zur Zahlung der Versicherungssteuer verpflichtet sein.'
}

class Tarifdaten extends React.Component<ITarifdatenProps, ITarifdatenState> {

    constructor(props: ITarifdatenProps) {
        super(props);
        this.state = this.getInitialState();
    };

    getInitialState = () => ({
        geburtsdatumVn: this.props.angebot.personenDaten.vn.geburtsdatum,
        geburtsdatumVp: this.props.angebot.personenDaten.vp ? this.props.angebot.personenDaten.vp.geburtsdatum : undefined,
        selectedInsuranceStart: getSelectedInsuranceStart(this.props.angebot.insuranceStartDates) as moment.Moment,
        isVnDatepickerTouched: false,
        isVpDatepickerTouched: false,
        isSubmitButtonLoading: false,
        isVnGleichVp: !this.props.angebot.personenDaten.vp,
        isDuwWarningDisplayed: false,
        hintModelOpened: false,
        hintCheckboxAccepted: !!this.props.isAgentur
    });

    isScreenValid = () => !hasBirthdayErrors({
        businessRules: this.props.businessRules as IBusinessRules,
        isSelfInsured: this.state.isVnGleichVp,
        selectedInsuranceStart: this.state.selectedInsuranceStart,
        isAgent: !!this.props.isAgentur,
        insurerBirthDate: this.state.geburtsdatumVn,
        ...(this.state.isVnGleichVp ?
            { insuredBirthDate: this.state.geburtsdatumVn } :
            { insuredBirthDate: this.state.geburtsdatumVp })
    }) && (this.state.hintCheckboxAccepted || !this.props.isAgentur)

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // double check validation (in case of changed within the datepicker)
        if (this.isScreenValid()) {
            const isDuwWarningAlreadyDisplayed = this.state.isDuwWarningDisplayed;
            const isDuwWarningNowDisplayed = hasChangesForcingDuwReset(this.props.angebot, this.state.isVnGleichVp, this.state.selectedInsuranceStart, this.state.geburtsdatumVn as moment.Moment);
            this.setState({ isDuwWarningDisplayed: isDuwWarningNowDisplayed });

            if (!isDuwWarningAlreadyDisplayed && isDuwWarningNowDisplayed) {
                return;
            }

            this.triggerSubmitTarifierung(false);
        }
    };

    triggerSubmitTarifierung = (resetDuw: boolean) => {
        this.setState({
            isSubmitButtonLoading: true,
            isDuwWarningDisplayed: false
        });
        this.props.onSubmitTarifierung(this.state.selectedInsuranceStart, this.state.geburtsdatumVn as moment.Moment, this.state.geburtsdatumVp, resetDuw);
    };

    handleChangeVersicherungsbeginn = (event: ChangeEvent<HTMLSelectElement>) => {
        const newDate = moment.utc(((event.target) as HTMLSelectElement).value);

        this.setState({
            selectedInsuranceStart: newDate,
        });
    };

    handleChangeGeburtsdatum = (isVn: boolean, date: moment.Moment | undefined) => {
        const identifier = isVn ? 'Vn' : 'Vp';
        const geburtsdatumProperty = `geburtsdatum${identifier}`;

        const newState: any = {
            [geburtsdatumProperty]: date,
        };

        if (date) {
            newState[`is${identifier}DatepickerTouched`] = true;
        }

        this.setState(newState);
    };

    handleBlurVn = () => {
        this.setState({ isVnDatepickerTouched: true });
    };

    handleBlurVp = () => {
        this.setState({ isVpDatepickerTouched: true });
    };

    handlehintModelOpened = () => {
        this.setState({ hintModelOpened: !this.state.hintModelOpened })
    }

    handleHintCheckbox = () => {
        this.setState({ hintCheckboxAccepted: !this.state.hintCheckboxAccepted })
    }

    isButtonDisabled = (conditions: boolean[]) => !conditions.every(Boolean);

    handleChangeVersichertePerson = (event: ChangeEvent<HTMLInputElement>) => {
        const isVnEqualVp = ((event.target) as HTMLInputElement).value === 'vn_gleich_vp';

        const gebDatVp = isVnEqualVp ? undefined : this.state.geburtsdatumVp;

        this.setState({
            isVnGleichVp: isVnEqualVp,
            geburtsdatumVp: gebDatVp,
            isVpDatepickerTouched: false,
        });
    };

    componentDidMount() {
        this.props.tracking();
    }

    render() {
        const StyledHeadline = styled.h3`
            margin: 1.7em 0 1.5em;
        `;
        const StyledTextLink = styled.span`
          color: #bf1528
          cursor: pointer;
        `
        const StyledIteration = styled.p`
        border-radius: 50%;
        width: 0.5em;
        height: 0.5em;
        font-size: 0.75em;
        padding: 6px;
        margin: 0;
        margin-right: 10px;
        background: #000;
        color: #fff;
        text-align: center;
        align-items: center;
        display: flex;
        `

        const StyledCustomBenefit = styled.p`
        display: flex;
        align-items: center;
        `

        return (
            <>
                {this.props.promotion && (
                    <PromotionTeaserPortal
                        label={this.props.promotion.label}
                        text={this.props.promotion.text}
                        image={this.props.promotion.imageSource}
                    />
                )}
                <StyledHeadline>Tarifdaten der zu versichernden Person</StyledHeadline>
                <form onSubmit={this.handleSubmit}>
                    <RadioGroupRow
                        data-component-id="vn_vp_radiogroup"
                        label="Wen möchten Sie versichern?"
                        name="Vp"
                        value={this.state.isVnGleichVp ? 'vn_gleich_vp' : 'vn_ungleich_vp'}
                        onChange={this.handleChangeVersichertePerson}
                    >
                        <Radio data-component-id="vn_gleich_vp" value="vn_gleich_vp" label="Mich selbst" />

                        { this.props.isAgentur ? (
                            <Radio
                                 data-component-id="vn_ungleich_vp"
                                value="vn_ungleich_vp"
                                label="Eine andere Person"
                            />
                        )
                        : (
                            <>
                            <Radio
                                disabled={true}
                                data-component-id="vn_ungleich_vp"
                                value="vn_ungleich_vp"
                                label={"Eine andere Person"}
                            />
                            <TooltipIcon>
                            Leider ist die Erstellung eines Online-Angebotes für eine andere Person
                            momentan nicht möglich. Kontaktieren Sie uns dafür bitte telefonisch.
                            </TooltipIcon>
                        </>
                        )}

                    </RadioGroupRow>

                    <DateInputRowWrapper
                        label={this.state.isVnGleichVp ? 'Ihr Geburtsdatum' : 'Ihr Geburtsdatum als Versicherungsnehmer'}
                        date={this.state.geburtsdatumVn}
                        dataComponentId="gebdat_vn"
                        onChange={(date) => {
                            this.handleChangeGeburtsdatum(true, date);
                        }}
                        error={this.state.isVnDatepickerTouched &&
                            hasGeburtstagsErrors(
                                this.state.geburtsdatumVn,
                                true,
                                (this.props.businessRules as IBusinessRules).birthdayRules,
                                this.state.selectedInsuranceStart,
                                !!this.props.isAgentur // isAgent
                            )}
                        onBlur={this.handleBlurVn}
                    />
                    <SelectRow
                        data-component-id="select_versicherungsbeginn"
                        onChange={this.handleChangeVersicherungsbeginn}
                        label="Versicherungsbeginn"
                        value={this.state.selectedInsuranceStart.toISOString()}
                    >
                        {
                            this.props.angebot.insuranceStartDates.map((item: IInsuranceStart, index: number) =>
                                <option
                                    value={item.insuranceStartDate.toISOString()}
                                    key={`${item.insuranceStartDate.toISOString()}-${index}`}
                                >
                                    {item.insuranceStartDate.format(uiDateFormat)}
                                </option>
                            )
                        }
                    </SelectRow>

                    {!this.state.isVnGleichVp && (
                        <>
                            <StyledHeadline>Zu versichernde Person </StyledHeadline>
                            <DateInputRowWrapper
                                label="Geburtsdatum zu versichernde Person"
                                date={this.state.geburtsdatumVp}
                                dataComponentId="gebdat_vp"
                                onChange={(date) => {
                                    this.handleChangeGeburtsdatum(false, date);
                                }}
                                error={this.state.isVpDatepickerTouched &&
                                    hasGeburtstagsErrors(this.state.geburtsdatumVp, false, (this.props.businessRules as IBusinessRules).birthdayRules, this.state.selectedInsuranceStart, !!this.props.isAgentur)}
                                onBlur={this.handleBlurVp}
                            />
                        </>
                    )}
                    {!this.state.isVnGleichVp && (
                        <Card {...hintConfiguration}>
                            <h3>{hintCopy.mainTitle}</h3>
                            <p>{hintCopy.firstBeforeAction}<StyledTextLink onClick={this.handlehintModelOpened}>{hintCopy.modalOpenAction}</StyledTextLink>{hintCopy.firstAfterAction}</p>
                            <p>{hintCopy.secondEntry}</p>
                            <Checkbox 
                            label={hintCopy.checkBoxValue} 
                            onChange={this.handleHintCheckbox}
                            checked={this.state.hintCheckboxAccepted}
                             />
                        </Card>
                    )}

                    {this.state.hintModelOpened && (
                        <Modal
                            extendedWidth
                            open={this.state.hintModelOpened}
                            onDismiss={this.handlehintModelOpened}
                            dismissible
                        >
                            <h3>{hintModalCopy.mainTitle}</h3>
                            {(hintModalCopy.menuPoints as string[]).map((entry,index) => 
                            <StyledCustomBenefit key={index}><StyledIteration>{index + 1}</StyledIteration>{entry}</StyledCustomBenefit>
                            )}
                            <p>{hintModalCopy.firstEntry}</p>
                            <p>{hintModalCopy.secondEntry}</p>

                        </Modal>
                    )}

                    {
                        this.state.isDuwWarningDisplayed && (
                            <Modal open={this.state.isDuwWarningDisplayed}>
                                {
                                    !this.props.angebot.personenDaten.vp && !this.state.isVnGleichVp // user switched from vn=vp to vn!=vp
                                        ? (
                                            <div>
                                                Im Fall, dass eine andere Person versichert wird, senden wir Ihnen die Gesundheitsfragen schriftlich zu.
                                                Die von Ihnen eingegebenen Antworten werden gelöscht.
                                            </div>
                                        )
                                        : (
                                            <div>
                                                Ihre Änderungen machen die Neuerfassung der Gesundheitsfragen notwendig.
                                            </div>
                                        )
                                }
                                Sollten Sie dies nicht wünschen wählen Sie bitte die Schaltfläche "Änderungen zurücknehmen"
                                <br />
                                <br />
                                <LayoutBox flex={[1, 1]}>
                                    <Button onClick={() => {
                                        this.triggerSubmitTarifierung(true);
                                    }} data-component-id="keepChanges">
                                        Änderungen beibehalten und neu berechnen
                                    </Button>
                                    <Button onClick={() => {
                                        this.setState(this.getInitialState());
                                    }} data-component-id="rejectChanges">
                                        Änderungen zurücknehmen
                                    </Button>
                                </LayoutBox>
                            </Modal>
                        )}

                    <Navigation
                        ctaWeiter={
                            <Button
                                type="submit"
                                data-component-id="button_tarifiere"
                                variant="primary"
                                loading={this.state.isSubmitButtonLoading}
                                disabled={!this.isScreenValid()}
                            >
                                Jetzt berechnen
                            </Button>}
                    />
                </form>
                <br />
                {this.props.promotion && (
                    <PromotionFootnoteContainer
                        footnoteUrl={this.props.promotion.footnoteUrl}
                        footnoteContent={this.props.promotion.footnote as string}
                        onFootnoteFetched={this.props.onFootnoteFetched}
                    />
                )}
            </>
        );
    }
}

export default Tarifdaten;
