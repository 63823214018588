import { IPersonendatenPersonReference } from '../model/output/IPersonendatenFinishedOutput'
import { IPersonReference } from '../../../infrastructure/model/ITarifierung'

export const mapToPersonReference = (person: IPersonendatenPersonReference): IPersonReference => {
  return {
      personId: person?.id,
      addressId: person?.addressId,
      emailId: person?.emailId,
      paymentId: person?.paymentId,
      phoneId: person?.phoneId
  }
};
