import { Steps, Versandart, Zahlweise } from '../../consts';
import { IStaticDtmPageNames } from '../../state/model/IStaticValues';
import Tarifvariante from '../../state/model/Tarifvariante';

const getSeitenname = (currentStep: Steps, aemValues: IStaticDtmPageNames): string => {

    const pageNames = [
        {step: Steps.Tarifdaten,          pageName: aemValues.pageTarifdaten ? aemValues.pageTarifdaten : ''},
        {step: Steps.BeitragVorlaeufig,   pageName: aemValues.pageBeitrag    ? aemValues.pageBeitrag : ''},
        {step: Steps.Gesundheitsfragen,   pageName: aemValues.pageDuw        ? aemValues.pageDuw : ''},
        {step: Steps.BeitragAktualisiert, pageName: aemValues.pageBeitragAktualisiert ? aemValues.pageBeitragAktualisiert : ''},
        {step: Steps.Persoenliches,       pageName: aemValues.pagePersoenliches ? aemValues.pagePersoenliches : ''},
        {step: Steps.Checkout,            pageName: aemValues.pageAbschluss  ? aemValues.pageAbschluss : ''},
        {step: Steps.Feedback,            pageName: aemValues.pageFeedback   ? aemValues.pageFeedback : ''}
    ];

    const found = pageNames.find(x => x.step === currentStep);
    return found ? found.pageName : '';
};

const getTarifmodus = (step: Steps, abschlussArt: Versandart|undefined): string => {
    if (step !== Steps.Feedback || abschlussArt === undefined) {
        return '';
    }

    switch (abschlussArt) {
        case Versandart.Email:
            return 'EMAIL';
        case Versandart.Postalisch:
            return 'POST';
        default:
            return 'OAB'; // OAB = Online ABschluss
    }
};

const getJahresbeitrag = (beitrag: number|undefined, zahlweise: Zahlweise): string => {
    let beitragByTarif: number = beitrag ? beitrag : 0;

    let result: number;

    switch (zahlweise) {
        case Zahlweise.Monatlich:       result = beitragByTarif * 12;   break;
        case Zahlweise.Vierteljahrlich: result = beitragByTarif * 4;    break;
        case Zahlweise.Halbjahrlich:    result = beitragByTarif * 2;    break;
        case Zahlweise.Jahrlich:        result = beitragByTarif;        break;
        default: result = 0;
    }

    return result.toFixed(2);
};

const getVertriebsprodukt = (variante: Tarifvariante|undefined) => {
    if (variante === Tarifvariante.SZS) {
        return 'TARIF_SZS';

    } else if (variante === Tarifvariante.SZL) {
        return 'TARIF_SZL';
    }

    return 'TARIF_SZSSZL';
};

const getSeitenpfad = (): string => window.location.toString() ? window.location.toString() : '';
const getAngebotsnr = (angebotsnr: string|undefined): string => angebotsnr ? angebotsnr : '';

export {
    getSeitenname,
    getTarifmodus,
    getJahresbeitrag,
    getVertriebsprodukt,
    getSeitenpfad,
    getAngebotsnr
};
