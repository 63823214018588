import { Steps } from '../../consts';

const mapIndexToStepnavi = (index: number) => {
    switch (index) {
        case Steps.BeitragVorlaeufig:
        case Steps.BeitragAktualisiert:
        case Steps.Gesundheitsfragen:
        case Steps.ErrorSwitchToOffer:
            return 1;
        case Steps.Persoenliches:
            return 2;
        case Steps.Checkout:
            return 3;
        default:
            return index;
    }
};

const mapIndexToNavigation = (index: number) => {
    switch (index) {
        case 2:
            return Steps.Persoenliches;
        case 3:
            return Steps.Checkout;
        default:
            return index;
    }
};

export {
    mapIndexToNavigation,
    mapIndexToStepnavi
};
