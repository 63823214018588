import { IPersonendaten } from '../../state/model/IPersonendaten';
import { IPersonReference } from '../../infrastructure/model/ITarifierung'

export const handleChangedPersonIds = (
    businessId: string,
    newPersonalData: IPersonendaten,
    oldPersonalData: IPersonendaten,
    updatePersons: (
        businessid: string,
        insuree: IPersonReference,
        insuredPerson?: IPersonReference
    ) => Promise<void>,
    onError: (e: Error | string) => void
) => {

    if (
        newPersonalData.vn.id !== oldPersonalData.vn.id ||
        (newPersonalData.vp && oldPersonalData.vp ? newPersonalData.vp.id !== oldPersonalData.vp.id : false) ||
        (!newPersonalData.vp && oldPersonalData.vp && oldPersonalData.vp.id)
    ) {
        try {
            updatePersons(
                businessId,
              { personId: newPersonalData.vn.id! },
                newPersonalData.vp ? { personId: newPersonalData.vp.id! } : undefined
            );
        } catch (e) {
            onError(e);
        }
    }
};
