import * as moment from 'moment';
import * as React from 'react';
import { isOnlineAbschlussMoeglich } from '../businessRules/duwRules';
import { getAgencyParams, IAgencyParams } from '../config/aem/agencyParamsExtractor';
import { Steps, Versandart, Zahlweise } from '../consts';
import { mapInsuranceStartDates } from '../infrastructure/gatewayMapper';
import { IGatewayClient } from '../infrastructure/IGatewayClient';
import { IRisiko, ITarifierung } from '../infrastructure/model/ITarifierung';
import { IKooperationsPartner } from '../infrastructure/model/request/ICalculationRequest';
import { IBasisData } from '../messaging/duw/model/IDuwInput';
import { mapToPersonReference } from '../messaging/personendatenerfassung/mapper/modelMapper';
import { IPersonendatenChangedOutput } from '../messaging/personendatenerfassung/model/output/IPersonendatenChangedOutput';
import { IPersonendatenFinishedOutput } from '../messaging/personendatenerfassung/model/output/IPersonendatenFinishedOutput';
import { getGewaehlteVarianteId, getSelectedInsuranceStart, getSelectedPricingModel } from '../state/helperFunctions';
import {
    checkoutAppLoadingState,
    createInitialAppState,
    duwAppLoadingState,
    IAppState,
    personenDatenAppLoadingState
} from '../state/model/AppState';
import childAppStatus from '../state/model/childAppStatus';
import { IChildApp } from '../state/model/IChildApp';
import { IStaticTrackingPageNames, IStaticValues } from '../state/model/IStaticValues';
import Tarifvariante from '../state/model/Tarifvariante';
import * as stateFunctions from '../state/stateFunctions';
import * as stateFunctionsCalc from '../state/stateFunctionsCalculation';
import TariffTracker from '../tracking/TariffTracker';
import { RenderedApp } from './RenderedApp';
import { handleChangedPersonIds } from './utility/appFunctions';

export interface IAppProps {
    personendaten: IChildApp;
    checkout: IChildApp;
    duw: IChildApp;
    className?: string;
    api: IGatewayClient;
    disableAemSpinner: () => void;
    staticValues: IStaticValues;
    onError: (e: Error | string) => void;
    scrollToTop: () => void;
}

const Script = require('react-load-script');

class App extends React.Component<IAppProps, IAppState> {
    private tracker: TariffTracker;
    private agencyParams: IAgencyParams;

    constructor(props: IAppProps, state: IAppState) {
        super(props, state);

        this.state = createInitialAppState(props.personendaten.url, props.checkout.url, props.duw.url);

        this.tracker = new TariffTracker(this.props.staticValues.trackingPageNames as IStaticTrackingPageNames);
        this.agencyParams = getAgencyParams();
    }

    handleChildappLoadingSuccess = (propToUpdate: string) => {
        this.setState((state: IAppState) =>
            stateFunctions.changeChildAppLoadingState(state, childAppStatus.loadingSuccessfullyFinished, propToUpdate)
        );
    };

    handleChildappLoadingFailed = (failhandler: ((state: IAppState) => IAppState) | undefined) => {
        if (failhandler === undefined) {
            this.props.onError('Error while loading childapp');
        } else {
            this.setState(failhandler);
        }
    };

    handleNavigation = (step: number) => {
        this.setState((state: IAppState) => stateFunctions.navigate(state, step));
    };

    handleFallbackToOfferDuw = () => {
        this.setState((state: IAppState) => stateFunctions.onlineSalePreventingErrorOccured(state));
        this.handleNavigation(Steps.ErrorSwitchToOffer);
    };

    handleTriggerPdfDownloaded = () => {
        // we can use window open method here
        alert('starting downloading pdf document');
    };

    loadProductBasics = async () => {
        try {
            const basics = await this.props.api.loadProductBasics();

            this.props.disableAemSpinner();
            this.setState((state) =>
                stateFunctions.processProductBasics(
                    state,
                    mapInsuranceStartDates(basics.insuranceBeginOptions),
                    basics.validation
                )
            );
        } catch (e) {
            this.props.onError(e);
        }
    };

    handleTriggerCalculation = (
        insuranceStart: moment.Moment,
        dateOfBirthInsuree: moment.Moment,
        dateOfBirthInsuredPerson: moment.Moment | undefined,
        resetDuw: boolean
    ) => {
        this.setState(
            (state) =>
                stateFunctions.basicDataSpecified(state, insuranceStart, dateOfBirthInsuree, dateOfBirthInsuredPerson),
            () => {
                this.handleCalculation(resetDuw).then();
                const beitraege = [
                    ...this.state.angebot?.szs?.beitraege,
                    ...this.state.angebot?.szl?.beitraege,
                ];
                //Saving payment method selected by user
                const selectierterBeitrag = beitraege.find(beitrag => beitrag.isSelected);
                //Making the flag false so that invalid age users can not see SZS tarrif
                beitraege.forEach(beitrag => beitrag.isSelected = false);
                //Adding below line only for SZL tarrif as SZS tarrif can be null in certain age condtions 
                this.state.angebot.szl.beitraege.forEach(beitrag => beitrag.zahlweise === selectierterBeitrag?.zahlweise ? beitrag.isSelected = true: false);
            }
        );
    };

    handleCalculation = async (resetDuw: boolean) => {
        if (resetDuw) {
            await this.handleResetDuw();
        }

        const { angebotId, personenDaten, insuranceStartDates } = this.state.angebot;

        let tarifierung: ITarifierung;
        const { agencyParams } = this;
        this.setState({ offerParams: agencyParams });
        try {
            tarifierung = await this.props.api.calculate(
                angebotId as string,
                getSelectedPricingModel(this.state),
                personenDaten.vn,
                personenDaten.vp,
                getSelectedInsuranceStart(insuranceStartDates) as moment.Moment,
                this.props.staticValues.agentur,
                agencyParams.aktionsnummer,
                this.props.staticValues.kooperationsPartner as IKooperationsPartner,
                agencyParams.vermittlernummer,
                agencyParams.oeNummer
            );
        } catch (e) {
            this.props.onError(e);
            return;
        }

        this.processCalculationResult(tarifierung, Steps.BeitragVorlaeufig);
    };

    processCalculationResult = (tarifierung: ITarifierung, nextStep: Steps) => {
        this.setState(
            (state) => stateFunctionsCalc.processCalculationResult(state, tarifierung, true),
            () => {
                this.handleNavigation(nextStep);
            }
        );
    };

    handleFinalizeDuw = async () => {
        let tarifierung: ITarifierung;
        const { angebotId, token, personenDaten } = this.state.angebot;
        try {
            tarifierung = await this.props.api.recalculateBasedOnUpdatedDuw(
                angebotId as string,
                token as string,
                personenDaten.vn,
                personenDaten.vp ? personenDaten.vp : undefined
            );

            const nextStep = isOnlineAbschlussMoeglich((tarifierung.risiko as IRisiko).duwErgebnis)
                ? Steps.BeitragAktualisiert
                : Steps.Feedback;

            this.processCalculationResult(tarifierung, nextStep);
        } catch (e) {
            this.props.onError(e);
            return;
        }
    };

    handleChangeSelectedTarifvariante = (tariffOption: Tarifvariante) => {
        this.setState((state) => stateFunctions.changeTariffVariant(state, tariffOption));
    };

    handleChangePaymentOption = async (pricingModel: Zahlweise) => {
        try {
            const hasCachedPricingModel = !!this.state.angebot?.szl?.beitraege?.find(
                (beitrag) => beitrag.zahlweise === pricingModel
            );
            if (!hasCachedPricingModel) {
                const calculation = await this.props.api.choosePricingModel(
                    this.state.angebot.angebotId!,
                    pricingModel
                );
                this.setState(stateFunctionsCalc.processCalculationResult(this.state, calculation));
            }
            this.setState((state) => stateFunctions.zahlweiseGeaendert(state, pricingModel));
        } catch (e) {
            this.props.onError(e);
            return;
        }
    };

    handleFinishedPersonendaten = async (output: IPersonendatenFinishedOutput, versandart: Versandart) => {
        const updatePersons = async () => {
            const insuree = mapToPersonReference(output.persons[0]);
            const insuredPerson = output.persons[1] ? mapToPersonReference(output.persons[1]) : undefined;
            await this.props.api.updatePersons(this.state.angebot.angebotId!, insuree, insuredPerson);
        };
        const checkOffer = async () => {
            try {
                await this.props.api.checkOffer(this.state.angebot.angebotId!);
            } catch (e) {
                this.props.onError(e);
            }
        };
        if (versandart === Versandart.Online) {
            if (this.state.personenDatenMeta.personendatenFuerOnlineAbschlussKomplett) {
                await updatePersons();
                await checkOffer();
                this.handleNavigation(Steps.Checkout);
            }
        } else {
            if (this.state.personenDatenMeta.personendatenFuerAngebotKomplett) {
                await updatePersons();
                await checkOffer();
                await this.handleSendOffer(versandart);
            }
        }
    };
    handleVariantChosen = async () => {
        try {
            await this.props.api.chooseVariant(
                this.state.angebot.angebotId!,
                getGewaehlteVarianteId(this.state),
                getSelectedPricingModel(this.state)
            );
        } catch (e) {
            this.props.onError(e);
        }
    };

    handleResetDuw = async (reRenderDuw?: () => void) => {
        try {
            await this.props.api.resetDuw(this.state.angebot.angebotId!);
        } catch (e) {
            this.props.onError(e);
            return;
        }

        this.setState(stateFunctions.duwResetted, reRenderDuw);
    };

    handleNotifyCaseId = (token: string, baseData: IBasisData) => {
        this.setState((state) => stateFunctions.tokenReceived(state, token, baseData));
    };

    handleChangePersonalData = (personalData: IPersonendatenChangedOutput) => {
        this.setState((state) => stateFunctions.personalDataChanged(state, personalData));
    };

    handleSendOffer = async (versandart: Versandart) => {
        this.setState((state) => stateFunctions.changeDispatch(state, versandart));

        try {
            await this.props.api.sendOffer(this.state.angebot.angebotId!, versandart);
        } catch (e) {
            this.props.onError(e);
            return;
        }
        this.handleNavigation(Steps.Feedback);
    };
    handleDocumentDownload = async (finishCallback: () => void) => {
        if (window) window.open(`${this.props.api.baseGatewayUrl}/offers/${this.state.angebot.angebotId}/download`);
        finishCallback();
    };
    handleFootnoteFetched = (content: string) => {
        this.setState((state) => stateFunctions.promotionFootenoteFetched(state, content));
    };

    componentDidMount() {
        this.loadProductBasics().then();
    }

    componentDidUpdate(prevProps: IAppProps, prevState: IAppState) {
        if (prevState.indexOfActiveStep !== this.state.indexOfActiveStep) {
            this.props.scrollToTop();
        }

        handleChangedPersonIds(
            this.state.angebot.angebotId as string,
            this.state.angebot.personenDaten,
            prevState.angebot.personenDaten,
            this.props.api.updatePersons,
            this.props.onError
        );
    }

    render() {
        return (
            <>
                <Script
                    url={this.props.personendaten.url}
                    defer={true}
                    onLoad={() => {
                        this.handleChildappLoadingSuccess(personenDatenAppLoadingState);
                    }}
                    onError={() => {
                        this.handleChildappLoadingFailed(this.props.personendaten.customFailHandler);
                    }}
                />

                <Script
                    url={this.props.checkout.url}
                    defer={true}
                    onLoad={() => {
                        this.handleChildappLoadingSuccess(checkoutAppLoadingState);
                    }}
                    onError={() => {
                        this.handleChildappLoadingFailed(this.props.checkout.customFailHandler);
                    }}
                />

                <Script
                    url={this.props.duw.url}
                    defer={true}
                    onLoad={() => {
                        this.handleChildappLoadingSuccess(duwAppLoadingState);
                    }}
                    onError={() => {
                        this.handleChildappLoadingFailed(this.props.duw.customFailHandler);
                    }}
                />

                <RenderedApp
                    appState={this.state as IAppState}
                    appProps={this.props as IAppProps}
                    appFunctions={{
                        onError: this.props.onError,
                        handleBasicsEntered: this.handleTriggerCalculation,
                        handleNavigation: this.handleNavigation,
                        handleChangeSelectedTarifvariante: this.handleChangeSelectedTarifvariante,
                        handleChangeVariant: this.handleVariantChosen,
                        handleChangePaymentOption: this.handleChangePaymentOption,
                        handleFinalizeDuw: this.handleFinalizeDuw,
                        handleResetDuw: this.handleResetDuw,
                        handleNotifyCaseId: this.handleNotifyCaseId,
                        handleChangePersonalData: this.handleChangePersonalData,
                        handleFallbackToOfferDuw: this.handleFallbackToOfferDuw,
                        handleFootnoteFetched: this.handleFootnoteFetched,
                        handleSendOffer: this.handleSendOffer,
                        handleDocumentDownload: this.handleDocumentDownload,
                        onFinishedPersonendaten: this.handleFinishedPersonendaten,
                        trackFunctions: {
                            trackBasisDataPage: () => this.tracker.trackBasicData(this.state),
                            trackCalcPage: () => this.tracker.trackDuePage(this.state),
                            trackDuwPage: () => this.tracker.trackDuwPage(this.state),
                            trackBeitragInclRisikoPage: () => this.tracker.trackBeitragInclRisiko(this.state),
                            trackPersonalDataPage: () => this.tracker.trackPersonalDataPage(this.state),
                            trackCheckoutPage: () => this.tracker.trackCheckout(this.state),
                            trackFeedbackPage: () => this.tracker.trackFeedbackPage(this.state)
                        }
                    }}
                />
            </>
        );
    }
}

export default App;
