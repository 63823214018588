import { IVpInput } from './IVpInput';
import { IVnInput } from './IVnInput';
import { THEME } from '@eg/elements/components/Provider';
import { ICreditor } from './ICreditor';
import { IPersonendatenChangedOutput } from '../output/IPersonendatenChangedOutput';
import { IPromotionInput } from './IPromotionInput';
import { IPersonendatenFinishedOutput } from '../output/IPersonendatenFinishedOutput'

export interface ICallbacks {
    onError: (e: Error) => void;
    onChangedPersonendaten: (output: IPersonendatenChangedOutput) => void;
    onSubmitPersonendaten: () => void;
    onFinishedPersonendaten: (output: IPersonendatenFinishedOutput) => void;
    onValidationFailed: () => void;
}

export enum PersonendatenRepository {
    SPCS = 'spcs',
    PDS = 'pds'
}
export interface IPersonendatenInput {
    repository: PersonendatenRepository,
    addressValidation: true,
    angebotsId: string;
    theme: THEME;
    apiRootUrl?: string;
    vn: IVnInput;
    vps?: IVpInput[];
    creditor: ICreditor;
    callbacks: ICallbacks;
    disableEwe: boolean;
    promotion: IPromotionInput;
}
