import { getSelectedInsuranceStart, isInsureeEqualToInsuredPerson } from '../state/helperFunctions';
import moment from 'moment';
import { IAppState } from '../state/model/AppState';
import { DuwErgebnis } from '../infrastructure/model/DuwErgebnis';
import IAngebot from '../state/model/IAngebot';
import { berechneEintrittsalter } from './birthdayRules';

const isDuwAgeConditionMet = (eintrittsalter: number): boolean => {
    return !(eintrittsalter < 18 || eintrittsalter > 60);
};

const isDuwAgeConditionMetForErgo = (eintrittsalter: number, isAgentur: boolean): boolean => {
    return (eintrittsalter > 60 && eintrittsalter < 69) && !isAgentur;
};

const isDuwPossible = (state: IAppState): boolean => {
    if (!isInsureeEqualToInsuredPerson(state.angebot.personenDaten)) {
        return false;
    }

    // mf 16-11-2020: szs and szl have always the same eintrittsalter, but szl is never null and has always the field eintrittsalter, whereas szs can be null
    return isDuwAgeConditionMet(
        state.angebot.szl.eintrittsalter
    );
};

const isOnlineAbschlussMoeglich = (ergebnis: DuwErgebnis | undefined) => {
    return ergebnis !== undefined && (ergebnis === DuwErgebnis.ANNAHME || ergebnis === DuwErgebnis.MANUELLE_RISIKOPRUEFUNG);
};

const hasChangesForcingDuwReset = (angebot: IAngebot, isVnGleichVp: boolean, changedInsuranceStart: moment.Moment, changedBirthdateVn: moment.Moment) => {

    const isDuwAlreadyExecuted = angebot.token !== undefined;
    const isVnGleichVpToggled = isVnGleichVp === Boolean(angebot.personenDaten.vp);
    const isEintrittsalterChanged = berechneEintrittsalter(changedInsuranceStart, changedBirthdateVn) !== berechneEintrittsalter(getSelectedInsuranceStart(angebot.insuranceStartDates) as moment.Moment, angebot.personenDaten.vn.geburtsdatum as moment.Moment);

    return isDuwAlreadyExecuted && (isVnGleichVpToggled || isEintrittsalterChanged);
};

export {
    isDuwPossible,
    isDuwAgeConditionMet,
    isDuwAgeConditionMetForErgo,
    isOnlineAbschlussMoeglich,
    hasChangesForcingDuwReset
};
