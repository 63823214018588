import * as React from 'react';
import * as moment from 'moment';
import { Button } from '@eg/elements/Button';
import { SummaryList } from '@eg/elements/SummaryList';
import styled from 'styled-components';
import { Steps, uiDateFormat } from '../../consts';
import EditIcon from '@eg/elements/components/Icons/EditIcon';

export interface ITarifdatenAendernProps {
    dateOfBirthInsuree: moment.Moment;
    dateOfBirthInsuredPerson: moment.Moment | undefined;
    insuranceStart: moment.Moment;
    onNavigate: (newStep: number) => void;
}

const TarifdatenAendern = (props: ITarifdatenAendernProps) => {

    const StyledDiv = styled.div`
        margin: 1.5em 0;
    `;

    const StyledHeadline = styled.h3`
            margin-top: 1.7em;
        `;

    return (
            <>
                <StyledHeadline>Ihre Angaben</StyledHeadline>

                <StyledDiv>
                    <SummaryList>
                        <dt>Geburtsdatum Versicherungsnehmer</dt>
                        <dd data-component-id="geburtsdatumVn_anzeige">{props.dateOfBirthInsuree.format(uiDateFormat)}</dd>

                        {props.dateOfBirthInsuredPerson != null
                            && <dt>Geburtsdatum zu versichernde Person</dt>}

                        {props.dateOfBirthInsuredPerson != null
                            && <dd data-component-id="geburtsdatumVp_anzeige">{props.dateOfBirthInsuredPerson.format(uiDateFormat)}</dd>}

                        <dt>Versicherungsbeginn</dt>
                        <dd data-component-id="versicherungsbeginn_anzeige">{props.insuranceStart.format(uiDateFormat)}</dd>
                    </SummaryList>

                    <Button
                        data-component-id="button_tarifdatenAendern"
                        type="button"
                        variant="text-link"
                        iconLeft={EditIcon}
                        onClick={() => props.onNavigate(Steps.Tarifdaten)}
                    >
                        Tarifdaten ändern
                    </Button>

                </StyledDiv>
            </>
        );
};

export default TarifdatenAendern;
