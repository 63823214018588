import { AemConfig, AemConfigKey, AemConfigValue, AemEnv } from '../../types/AemConfig'

export const getAemConfig = (): AemConfig => {
    const appId = document.querySelector("[id^='szsszl-otr']")?.id || ''
    const params = window.params || {};
    return params[appId] || {};
}
export const getAemConfigVariable = (variable: AemConfigKey): AemConfigValue => {
    return getAemConfig()[variable] as AemConfigValue;
};

/*
 APP can be integrated in custom domain and this is the way how it can resolve its BFF location
 */
export const getBackendHost = (): string => {
    const envs = (getAemConfigVariable('envs') as AemEnv[]) || [];
    const host = envs[0]?.url || '';
    return `${host}`;
}