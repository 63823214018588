import * as React from 'react';
import ComparisonTable from '@eg/elements/components/ComparisonTable';
import MinusIcon from '@eg/elements/components/Icons/MinusIcon';
import Price from '@eg/elements/components/Price';
import Radio from '@eg/elements/components/Radio';
import Select from '@eg/elements/components/Select';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { szsAnnotation, vorlaeufigAnnotation, Zahlweise } from '../../../consts';
import { getBeitragByZahlweiseWithoutRisk } from '../../../state/helperFunctions';
import { IVariante } from '../../../state/model/IVariante';
import Tarifvariante from '../../../state/model/Tarifvariante';
import Annotation from '../../Annotation';
import AnnotationContainer from '../../AnnotationContainer';
import { TooltipErsatzKrankenhaustagegeld, TooltipFreieArztwahl, TooltipRoomingIn, TooltipTariffInfoSzl, TooltipTariffInfoSzs } from '../../TooltipSZ';
import BigGreenCheck from './BigGreenCheck';

const addBenefit = (szsVisible: boolean, text: string, szs: boolean, szl: boolean, tooltip?: JSX.Element) => {
    return (
        <tr data-component-id="benefit">
            <td>
                {text}
                {tooltip}
            </td>
            {szsVisible && <td>{szs ? <BigGreenCheck/> : <MinusIcon/>}</td>}
            <td>{szl ? <BigGreenCheck/> : <MinusIcon/>}</td>
        </tr>
    );
};

interface ITableProps {
    isRoomingInVisible: boolean;
    szs: IVariante | null;
    szl: IVariante;
    selectedTariff: Tarifvariante | undefined;
    selectedZahlweise: Zahlweise;
    changeSelectedTariff: (variant: Tarifvariante) => void;
    changeSelectedPaymentOption: (paymentOption: Zahlweise) => void;
}

export const TariffTable = (props: ITableProps) => {
    const StyledContributionHeadline = styled.div`
        font-weight: bold;
        margin-bottom: 10px;
    `;

    const isSzsVisible = props.szs !== null && props.szs.beitraege.length > 0;

    const convertPaymentOption = (event: ChangeEvent<HTMLSelectElement>) => (event.target).value as Zahlweise;

    const columClickHandler = (c: number) => {
        if (isSzsVisible) {
            props.changeSelectedTariff(c === 1 ? Tarifvariante.SZS : Tarifvariante.SZL);
        }
    };

    const selectedColumn = props.selectedTariff
        ? (props.selectedTariff === Tarifvariante.SZS ? 1 : 2)
        : undefined;

    return (
        <>
            <ComparisonTable
                selectedColumn={selectedColumn}
                onColumnClick={columClickHandler}
                variant="rich"
            >
                <thead>
                <tr>
                    <th/>
                    {isSzsVisible && (
                        <th>
                            <b>Günstiger Einstieg</b><br/>
                            (SZS)<br/>
                            <TooltipTariffInfoSzs/>
                        </th>
                    )}

                    <th>
                        <b>Stabiler Beitrag</b><br/>
                        (SZL)<br/>
                        <TooltipTariffInfoSzl/>
                    </th>
                </tr>

                <tr>
                    <th>
                        <StyledContributionHeadline><span>Ihr vorläufiger<Annotation order={1}/> Zahlbeitrag</span></StyledContributionHeadline>
                        <Select
                            data-component-id="zahlweise_aendern"
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => props.changeSelectedPaymentOption(convertPaymentOption(e))}
                            value={props.selectedZahlweise}
                            disabled={!props.selectedTariff}
                        >
                            <option value={Zahlweise.Monatlich}>{Zahlweise.Monatlich.toString().toLowerCase()}</option>
                            <option value={Zahlweise.Vierteljahrlich}>{Zahlweise.Vierteljahrlich.toString().toLowerCase()}</option>
                            <option value={Zahlweise.Halbjahrlich}>{Zahlweise.Halbjahrlich.toString().toLowerCase()}</option>
                            <option value={Zahlweise.Jahrlich}>{Zahlweise.Jahrlich.toString().toLowerCase()}</option>
                        </Select>
                    </th>

                    {isSzsVisible && (
                        <th>
                            <Price
                                value={getBeitragByZahlweiseWithoutRisk(props.szs as IVariante, props.selectedZahlweise)}
                                data-component-id="fee-szs"
                            />
                            <Annotation order={2}/>
                            <br/>
                            <Radio
                                name="selectedTarifvariante"
                                value={Tarifvariante.SZS}
                                onChange={() => props.changeSelectedTariff(Tarifvariante.SZS)}
                                checked={props.selectedTariff === Tarifvariante.SZS}
                                label="Auswählen"
                            />
                        </th>
                    )}

                    <th>
                        <Price
                            value={getBeitragByZahlweiseWithoutRisk(props.szl, props.selectedZahlweise)}
                            data-component-id="fee-szl"
                        /><br/>
                        {(
                            <Radio
                                name="selectedTarifvariante"
                                value={Tarifvariante.SZL}
                                onChange={() => props.changeSelectedTariff(Tarifvariante.SZL)}
                                checked={props.selectedTariff === Tarifvariante.SZL}
                                label="Auswählen"
                            />
                        )}
                    </th>
                </tr>
                </thead>
                <tbody>
                {addBenefit(isSzsVisible, 'Freie Arztwahl', true, true, <TooltipFreieArztwahl/>)}
                {addBenefit(isSzsVisible, 'Ein- oder Zweibettzimmer', true, true)}
                {addBenefit(isSzsVisible, 'Ersatz-Krankenhaustagegeld bis zu 100 € pro Tag', true, true, <TooltipErsatzKrankenhaustagegeld/>)}
                {props.isRoomingInVisible && addBenefit(false, 'Rooming-in', false, true, <TooltipRoomingIn/>)}
                </tbody>
            </ComparisonTable>
            <br/>
            <AnnotationContainer annotations={[vorlaeufigAnnotation].concat(isSzsVisible ? [szsAnnotation] : [])}/>
        </>
    );
};
