/* tslint:disable-next-line */
const sanitizeHtml = require('sanitize-html');

export const fetchContent = async (url: string): Promise<string> => {
    try {
        const response = await fetch(url, {
            method: 'GET'
        });

        if (response && response.ok) {
            const content = await response.text();
            return sanitizeHtml(content);
        }
    } catch (e) {
    }
    return '';
};
