import * as React from 'react';
import { FormFooter } from '@eg/elements/FormFooter';
import styled from 'styled-components';
import { ConsultingInfo } from '@eg/idd-components';

interface INavigationProps {
    hasConsultingInfo?: boolean;
    ctaWeiter: JSX.Element;
    ctaBack?: JSX.Element;
}

const Hr = styled.hr`
    height: 1px;
    background: #ddd;
    margin: 2em 0;
    border: 0;
`;

const Container = styled.div`
    width: 100%;
    display: inline-block;
`;

const Left = styled.div`
    float: left;
`;

const Right = styled.div`
    float: right;
`;

const ConsultingInfoContainer = styled.div`
    clear: right;
    padding-top: 1em;
`;

const Navigation: React.FunctionComponent<INavigationProps> = props => {

    const linkBack = props.ctaBack ? props.ctaBack : null;

    return (
        <div data-component-id="navigation">
            <Hr />
            <Container>
                <Left>{linkBack}</Left>
                <Right>
                    {props.ctaWeiter}
                    {props.hasConsultingInfo && (
                        <ConsultingInfoContainer>
                            <ConsultingInfo showTelephonenumber={false} />
                        </ConsultingInfoContainer>
                    )}
                </Right>
            </Container>
            <FormFooter />
        </div>
    );
};

export default Navigation;
