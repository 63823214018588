import * as React from 'react';
import EmailIcon from '@eg/elements/components/Icons/EmailIcon';
import Button from '@eg/elements/Button';

const Newsletter: React.StatelessComponent = () => {
    return (
        <>
            <h3>Newsletter</h3>

            <b>Immer auf dem neuesten Stand</b><br/>
            Neuigkeiten und Tipps rund um Alltag, Sicherheit und Vorsorge: Mit unserem Newsletter haben Sie immer die Nase vorn.<br/><br/>

            <a href="/content/ergo/ergo-one/ergo-one-portal/de/Service/Newsletter.html">
                <Button
                    iconLeft={EmailIcon}
                    variant="primary"
                    data-component-id="button_newsletter"
                >
                    Hier zum Newsletter anmelden
                </Button>
            </a>
        </>
    );
};

export default Newsletter;
