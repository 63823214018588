import * as React from 'react';
import { IPersonendatenMessaging } from '../messaging/personendatenerfassung/personendatenerfassungMessaging';
import { ICheckoutMessaging } from '../messaging/checkout/checkoutMessaging';
import { Steps, Versandart, Zahlweise } from '../consts';
import { mapIndexToNavigation, mapIndexToStepnavi } from './utility/navigation';
import moment from 'moment';
import StepNavigation from '@eg/elements/components/StepNavigation';
import Step from './Step';
import Tarifdaten from './basicdata/Tarifdaten';
import { Provider } from '@eg/elements/Provider';
import DueProvisonally from './dues/DueProvisonally';
import DueRecalculated from './dues/DueRecalculated';
import Persoenliches from './Persoenliches';
import Checkout from './checkout/Checkout';
import Feedback from './feedback/Feedback';
import ErrorSwitchToOffer from './feedback/ErrorSwitchToOffer';
import DtmWidget from './dtm/DtmWidget';
import { IAppProps } from './App';
import Tarifvariante from '../state/model/Tarifvariante';
import { IAppState } from '../state/model/AppState';
import {
    getBetrag,
    getGewaehlteVariante,
    getSelectedPricingModel,
    getSelectedInsuranceStart,
    isInsureeEqualToInsuredPerson,
    isRoomingInAvailable
} from '../state/helperFunctions';
import { IDuwMessaging } from '../messaging/duw/duwMessaging';
import { IBasisData } from '../messaging/duw/model/IDuwInput';
import Duw from './dues/Duw';
import { IPersonendatenChangedOutput } from '../messaging/personendatenerfassung/model/output/IPersonendatenChangedOutput';
import { isDuwPossible } from '../businessRules/duwRules';
import { IRisiko } from '../infrastructure/model/ITarifierung';
import Geschlecht from '../state/model/Geschlecht';
import { IPersonVp } from '../state/model/IPersonVp';
import { IPersonendatenFinishedOutput } from '../messaging/personendatenerfassung/model/output/IPersonendatenFinishedOutput';
import { ChannelType } from '../types/Agency';
import { getEnvVar } from '../config/config'

export interface IAppFunctions {
    handleBasicsEntered: (
        insuranceStart: moment.Moment,
        dateOfBirthInsuree: moment.Moment,
        dateOfBirthInsuredPerson: moment.Moment | undefined,
        resetDuw: boolean
    ) => void;
    handleNavigation: (step: number) => void;
    onError: (e: Error) => void;
    handleChangeSelectedTarifvariante: (tariffOption: Tarifvariante) => void;
    handleChangePaymentOption: (paymentOption: Zahlweise) => void;
    handleChangeVariant: () => void;
    handleFinalizeDuw: () => void;
    handleResetDuw: (callback: () => void) => void;
    handleNotifyCaseId: (token: string, baseData: IBasisData) => void;
    handleChangePersonalData: (personendaten: IPersonendatenChangedOutput) => void;
    handleFallbackToOfferDuw: () => void;
    handleSendOffer: (versandArt: Versandart) => void;
    handleDocumentDownload: (finishCallback: () => void) => void;
    handleFootnoteFetched: (content: string) => void;
    onFinishedPersonendaten: (output: IPersonendatenFinishedOutput, versandart: Versandart) => void;
    trackFunctions: {
        trackBasisDataPage: () => void;
        trackCalcPage: () => void;
        trackDuwPage: () => void;
        trackBeitragInclRisikoPage: () => void;
        trackPersonalDataPage: () => void;
        trackCheckoutPage: () => void;
        trackFeedbackPage: () => void;
    };
}

interface IAppRenderedProps {
    appState: IAppState;
    appProps: IAppProps;
    appFunctions: IAppFunctions;
}

const RenderedApp = (props: IAppRenderedProps) => {
    if (props.appState.isLoading) {
        return null;
    }

    const isActiveStep = (index: number) => props.appState.indexOfActiveStep === index;

    // const isAgentur = props.appProps.staticValues.agentur !== undefined;
    const isAgentur = false; // disabled temporarely (business requirement)

    return (
        <Provider theme={props.appProps.staticValues.theme} style={{ padding: '20px' }}>
            <div id="promotion-teaser" />

            <StepNavigation
                steps={['Tarifdaten', 'Beitrag', 'Persönliches', 'Abschluss']}
                activeStepIndex={mapIndexToStepnavi(props.appState.indexOfActiveStep)}
                goToStepByIndex={
                    isActiveStep(Steps.Feedback)
                        ? undefined
                        : (index: number) => props.appFunctions.handleNavigation(mapIndexToNavigation(index))
                }
            />

            <Step isActiveStep={isActiveStep(Steps.Tarifdaten)}>
                <Tarifdaten
                    angebot={props.appState.angebot}
                    onSubmitTarifierung={props.appFunctions.handleBasicsEntered}
                    businessRules={props.appState.businessRules}
                    tracking={props.appFunctions.trackFunctions.trackBasisDataPage}
                    promotion={
                        props.appProps.staticValues.promotion
                            ? {
                                  ...props.appProps.staticValues.promotion,
                                  footnote: props.appState.promotionFootnote
                              }
                            : undefined
                    }
                    onFootnoteFetched={props.appFunctions.handleFootnoteFetched}
                    isAgentur={isAgentur}
                />
            </Step>

            <Step isActiveStep={isActiveStep(Steps.BeitragVorlaeufig)}>
                <DueProvisonally
                    angebot={props.appState.angebot}
                    selectedTariffVariant={getGewaehlteVariante(props.appState)}
                    selectedPaymentMethod={getSelectedPricingModel(props.appState)}
                    changeSelectedTariff={props.appFunctions.handleChangeSelectedTarifvariante}
                    changePaymentMethod={props.appFunctions.handleChangePaymentOption}
                    onChangeVariant={props.appFunctions.handleChangeVariant}
                    onNavigate={props.appFunctions.handleNavigation}
                    isDuwPossible={!props.appState.isOnlineAbschlussDefekt && isDuwPossible(props.appState)}
                    tracking={props.appFunctions.trackFunctions.trackCalcPage}
                    isRoomingInAvailable={isRoomingInAvailable(props.appState)}
                    isAgentur={isAgentur}
                    hideRequestQuoteButton={props.appState.offerParams.channelType === ChannelType.AGENT_BROKER}
                />
            </Step>

            <Step isActiveStep={isActiveStep(Steps.Gesundheitsfragen)}>
                <Duw
                    theme={props.appProps.staticValues.theme}
                    apiRootUrl={getEnvVar('CONFIG_DUW_HOST')}
                    duwAppLoadingState={props.appState.duwAppLoadingState}
                    onNavigate={props.appFunctions.handleNavigation}
                    onFinalize={props.appFunctions.handleFinalizeDuw}
                    onNotifyCaseId={props.appFunctions.handleNotifyCaseId}
                    onError={props.appFunctions.handleFallbackToOfferDuw}
                    onResetDuwResult={props.appFunctions.handleResetDuw}
                    messaging={props.appProps.duw.messaging as IDuwMessaging}
                    token={props.appState.angebot.token}
                    hasResult={props.appState.angebot.risiko !== undefined}
                    person={
                        isInsureeEqualToInsuredPerson(props.appState.angebot.personenDaten)
                            ? props.appState.angebot.personenDaten.vn
                            : (props.appState.angebot.personenDaten.vp as IPersonVp)
                    }
                    pdfLocation={props.appProps.staticValues.documents.vorvertraglicheAnzeigepflicht}
                    tracking={props.appFunctions.trackFunctions.trackCalcPage}
                    versicherungsBeginn={
                        getSelectedInsuranceStart(props.appState.angebot.insuranceStartDates) as moment.Moment
                    }
                />
            </Step>

            <Step isActiveStep={isActiveStep(Steps.BeitragAktualisiert)}>
                <DueRecalculated
                    selectedTariff={getGewaehlteVariante(props.appState) as Tarifvariante}
                    selectedPaymentMethod={getSelectedPricingModel(props.appState)}
                    onNavigate={props.appFunctions.handleNavigation}
                    isRoomingInAvailable={isRoomingInAvailable(props.appState)}
                    selectedDue={getBetrag(props.appState) as number}
                    risiko={props.appState.angebot.risiko as IRisiko}
                    tracking={props.appFunctions.trackFunctions.trackBeitragInclRisikoPage}
                />
            </Step>

            <Step isActiveStep={isActiveStep(Steps.Persoenliches)}>
                <Persoenliches
                    theme={props.appProps.staticValues.theme}
                    apiRootUrl={getEnvVar('CONFIG_PERSONDATA_HOST')}
                    messaging={props.appProps.personendaten.messaging as IPersonendatenMessaging}
                    personenDatenAppLoadingState={props.appState.personenDatenAppLoadingState}
                    offerData={props.appState.angebot}
                    moeglicheAbschlussArten={props.appState.personenDatenMeta}
                    onNavigate={props.appFunctions.handleNavigation}
                    tracking={props.appFunctions.trackFunctions.trackPersonalDataPage}
                    onError={props.appFunctions.onError}
                    onChangedPersonalData={props.appFunctions.handleChangePersonalData}
                    tariff={getGewaehlteVariante(props.appState)}
                    aktionsnummer={props.appState.offerParams.aktionsnummer}
                    promotion={props.appProps.staticValues.promotion}
                    isDuwPossible={!props.appState.isOnlineAbschlussDefekt && isDuwPossible(props.appState)}
                    duwErgebnis={props.appState.angebot.risiko ? props.appState.angebot.risiko.duwErgebnis : undefined}
                    onFinalize={props.appFunctions.onFinishedPersonendaten}
                />
            </Step>

            <Step isActiveStep={isActiveStep(Steps.Checkout)}>
                <Checkout
                    theme={props.appProps.staticValues.theme}
                    apiRootUrl={getEnvVar('CONFIG_CHECKOUT_HOST')}
                    apiDuwRootUrl={getEnvVar('CONFIG_DUW_HOST')}
                    businessId={props.appState.angebot.angebotId as string}
                    duwMessaging={props.appProps.duw.messaging as IDuwMessaging}
                    checkoutMessaging={props.appProps.checkout.messaging as ICheckoutMessaging}
                    checkoutAppLoadingState={props.appState.checkoutAppLoadingState}
                    token={props.appState.angebot.token as string}
                    isAgentur={isAgentur}
                    insuree={props.appState.angebot.personenDaten.vn}
                    insuredPerson={props.appState.angebot.personenDaten.vp}
                    versicherungsbeginn={
                        getSelectedInsuranceStart(props.appState.angebot.insuranceStartDates) as moment.Moment
                    }
                    tarifVariante={getGewaehlteVariante(props.appState) as Tarifvariante}
                    beitrag={getBetrag(props.appState) as number}
                    zahlweise={getSelectedPricingModel(props.appState)}
                    onNavigate={props.appFunctions.handleNavigation}
                    onCompleteOffer={props.appFunctions.handleSendOffer}
                    onError={props.appFunctions.onError}
                    tracking={props.appFunctions.trackFunctions.trackCheckoutPage}
                    downloadDocument={props.appFunctions.handleDocumentDownload}
                />
            </Step>

            <Step isActiveStep={isActiveStep(Steps.Feedback)}>
                <Feedback
                    versandart={props.appState.angebot.versandart as Versandart}
                    duwErgebnis={props.appState.angebot.risiko ? props.appState.angebot.risiko.duwErgebnis : undefined}
                    geschlecht={props.appState.angebot.personenDaten.vn.geschlecht as Geschlecht}
                    vorname={props.appState.angebot.personenDaten.vn.vorname as string}
                    nachname={props.appState.angebot.personenDaten.vn.nachname as string}
                    promotionSuccessful={props.appState.angebot.promotionSuccessful}
                    tracking={props.appFunctions.trackFunctions.trackFeedbackPage}
                    businessId={props.appState.angebot.angebotId as string}
                    versicherungsnehmerPdsId={props.appState.angebot.personenDaten.vn.id as string}
                    staticValues={props.appProps.staticValues}
                />
            </Step>

            <Step isActiveStep={isActiveStep(Steps.ErrorSwitchToOffer)}>
                <ErrorSwitchToOffer onContinue={() => props.appFunctions.handleNavigation(Steps.Persoenliches)} />
            </Step>

            <DtmWidget
                angebotsnr={props.appState.angebot.angebotId}
                tarifvariante={getGewaehlteVariante(props.appState)}
                beitrag={getBetrag(props.appState)}
                zahlweise={getSelectedPricingModel(props.appState)}
                step={props.appState.indexOfActiveStep}
                pageNames={props.appProps.staticValues.dtmPageNames}
                versandart={props.appState.angebot.versandart}
            />
            <br />
            <br />
        </Provider>
    );
};

export { RenderedApp };
