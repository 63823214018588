import * as React from 'react';

const Error = () => {
    return(
        <div data-component-id="error_page">
            <p><b>Zugriff derzeit nicht möglich!</b></p>
            <p>Leider ist momentan aufgrund von technischen Problemen die Erstellung eines Online-Angebotes nicht möglich.</p>
            <p>Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal oder kontaktieren Sie uns kostenfrei unter <b>0800 / 666 9000</b>.</p>
            <p>Vielen Dank für Ihr Verständnis.</p>
        </div>
    );
};

export default Error;
