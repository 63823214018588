import { mapToEventModel } from './mapper/checkoutMapper';
import { Zahlweise } from '../../consts';
import * as moment from 'moment';
import { IPersonVn } from '../../state/model/IPersonVn';
import { IPersonVp } from '../../state/model/IPersonVp';
import Tarifvariante from '../../state/model/Tarifvariante';
import { THEME } from '@eg/elements/components/Provider';
import { ICallbacks, ICheckoutInput, PdfDownloadControls } from './model/ICheckoutInput'

export interface ICheckoutMessaging {
    dispatchRenderCheckout: (
        theme: THEME,
        pdfDownloadControls: PdfDownloadControls,
        businessId: string,
        vn: IPersonVn,
        isAgentur: boolean,
        beitrag: number,
        zahlweise: Zahlweise,
        versicherungsbeginn: moment.Moment,
        onNavigate: (newStep: number) => void,
        isIddErrorMessageDisplayed: boolean,
        callbacks: ICallbacks,
        variante: Tarifvariante,
        duwHeadline: string,
        duwMountingPoint: string,
        apiRootUrl: string,
        vp?: IPersonVp
    ) => void;
    dispatchDisplayErrorMessages: () => void;
    dispatchUnmountCheckout: () => void;
}

export const checkoutMessaging: ICheckoutMessaging = {
    dispatchRenderCheckout: (
        theme,
        pdfDownloadControls: PdfDownloadControls,
        businessId,
        vn,
        isAgentur,
        beitrag,
        zahlweise,
        versicherungsbeginn,
        onNavigate,
        isIddErrorMessageDisplayed,
        callbacks,
        variante,
        duwHeadline,
        duwMountingPoint,
        apiRootUrl,
        vp?
    ) => {
        const checkoutInput: ICheckoutInput = mapToEventModel(
            theme,
            pdfDownloadControls,
            businessId,
            isAgentur,
            vn,
            beitrag,
            zahlweise,
            versicherungsbeginn,
            onNavigate,
            isIddErrorMessageDisplayed,
            callbacks,
            variante,
            duwHeadline,
            duwMountingPoint,
            apiRootUrl,
            vp);

        const changeEvent = new CustomEvent('renderCheckout', { detail: checkoutInput });

        document.dispatchEvent(changeEvent);
    },

    dispatchDisplayErrorMessages: () => {
        document.dispatchEvent(new CustomEvent('displayErrorMessages'));
    },

    dispatchUnmountCheckout: () => {
        document.dispatchEvent(new CustomEvent('unmountCheckout'));
    }
};
