import * as React from 'react';
import { TooltipIcon } from '@eg/elements/TooltipIcon';
import styled from 'styled-components';

const DefaultTooltip = styled(TooltipIcon)`
    margin-left: .3em;
`;

const TopTooltip = styled(TooltipIcon)`
    margin: 5px 0;
`;

const TooltipErsatzKrankenhaustagegeld = () => {

    return (
        <DefaultTooltip data-component-id="tooltip_ersatz_krankenhaustagegeld">
            Bei vollstationärer Unterbringung bekommen Sie Geld, wenn Sie auf eine oder mehrere
            dieser Leistungen verzichten:
            <br/><br/>
            <p>- Chefarztbehandlung oder wahlärztliche Leistung: 50 € pro Tag</p>
            <p>- Unterbringung im Einbettzimmer: 30 € pro Tag</p>
            <p>- Unterbringung im Zweibettzimmer: 50 € pro Tag</p>
            <br/>
            Kinder und Jugendliche bis 20 Jahre erhalten jeweils den halben Betrag.
        </DefaultTooltip>
    );
};

const TooltipRoomingIn = () => {
    return (
        <DefaultTooltip data-component-id="tooltip_rooming_in">
            Bei Kindern bis zur Vollendung des 16. Lebensjahrs übernehmen wir die Kosten für die
            Unterbringung eines Elternteils im Krankenhaus. Voraussetzung:
            Ihre gesetzliche Krankenversicherung erstattet die Kosten nicht.
        </DefaultTooltip>
    );
};

const TooltipFreieArztwahl = () => {
    return (
        <DefaultTooltip data-component-id="tooltip_freie_arztwahl">
            Der Versicherer übernimmt die Kosten – auch wenn Sie sich vom Chefarzt behandeln lassen. Das Krankenhaus können Sie
            ebenfalls frei wählen.
        </DefaultTooltip>
    );
};

const TooltipTariffInfoSzs = () => {
    return (
        <TopTooltip data-component-id="tooltip_szs_allgemein">
            Sie haben die Wahl zwischen 2 Tarifen mit denselben Leistungen:<br/><br/>

            <b>Günstiger Einstieg</b> (Tarif SZS): <br/>
            Sie steigen günstig ein. Nach und nach wird Ihr Beitrag erhöht, abhängig
            von Ihrer Altersgruppe (siehe Beitragsverlauf). Der Tarif endet mit Vollendung des 66. Lebensjahres und
            wird dann auf den Tarif SZL mit stabilem Beitrag umgestellt. Hier ist der dann gültige Beitrag des
            66-Jährigen fällig.<br/><br/>

            <b>Derzeitige Beiträge</b><br/>
            21-30 Jahre: 19,80 €<br/>
            31-40 Jahre: 27,70 €<br/>
            41-50 Jahre: 27,70 €<br/>
            51-55 Jahre: 35,70 €<br/>
            56-60 Jahre: 45,90 €<br/>
            61-65 Jahre: 61,20 €<br/>
            <br/>

            <b>Stabiler Beitrag</b> (Tarif SZL): <br/>
            Sie steigen etwas höher ein. Dafür wird mit Vollendung des 21.
            Lebensjahres eine Alterungsrückstellung gebildet. Damit sollen Beitragserhöhungen aufgrund des
            steigenden Alters der versicherten Person vermieden werden.

        </TopTooltip>
    );
};

const TooltipTariffInfoSzl = () => {
    return (
        <TopTooltip data-component-id="tooltip_szl_allgemein">
            Sie haben die Wahl zwischen 2 Tarifen mit denselben Leistungen:<br/><br/>
            <b>Stabiler Beitrag</b> (Tarif SZL): <br/>
            Sie steigen etwas höher ein. Dafür wird mit Vollendung des 21.
            Lebensjahres eine Alterungsrückstellung gebildet. Damit sollen Beitragserhöhungen aufgrund des
            steigenden Alters der versicherten Person vermieden werden.<br/><br/>

            <b>Günstiger Einstieg</b> (Tarif SZS): <br/>
            Sie steigen günstig ein. Nach und nach wird Ihr Beitrag erhöht, abhängig
            von Ihrer Altersgruppe (siehe Beitragsverlauf). Der Tarif endet mit Vollendung des 66. Lebensjahres und
            wird dann auf den Tarif SZL mit stabilem Beitrag umgestellt. Hier ist der dann gültige Beitrag des
            66-Jährigen fällig.

            <br/><br/>
            <b>Derzeitige Beiträge</b><br/>
            21-30 Jahre: 19,80 €<br/>
            31-40 Jahre: 27,70 €<br/>
            41-50 Jahre: 27,70 €<br/>
            51-55 Jahre: 35,70 €<br/>
            56-60 Jahre: 45,90 €<br/>
            61-65 Jahre: 61,20 €<br/>
        </TopTooltip>
    );
};

export {
    TooltipErsatzKrankenhaustagegeld,
    TooltipRoomingIn,
    TooltipTariffInfoSzs,
    TooltipTariffInfoSzl,
    TooltipFreieArztwahl
};
