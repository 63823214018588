import * as React from 'react';
import * as moment from 'moment';
import { DateInputValue } from '@eg/elements/components/DateInput';
import DateInputRow from '@eg/elements/components/DateInputRow';

export interface IDateInputRowWrapperProps {
    date: moment.Moment | undefined;
    onChange: (date: moment.Moment | undefined) => void;
    label: string;
    error: string | boolean;
    onBlur: () => void;
    dataComponentId: string;
}

const DateInputRowWrapper: React.FunctionComponent<IDateInputRowWrapperProps> = (props) => {

    const mapToDateInputValue = (source: moment.Moment | undefined) => {
        return source === undefined
            ? {day: '', month: '', year: ''}
            : {
                day: source.format('DD'),
                month: source.format('MM'),
                year: source.format('YYYY')
            };
    }

    const [input, setInput] = React.useState<DateInputValue>(mapToDateInputValue(props.date));

    return (
        <DateInputRow
            onChange={(dateInputValue: DateInputValue) => {
                setInput(dateInputValue);

                const dateStr = `${dateInputValue.year}-${dateInputValue.month}-${dateInputValue.day}`;

                const date = dateInputValue.day && dateInputValue.day !== '0' &&
                dateInputValue.month && dateInputValue.month !== '0' &&
                dateInputValue.year && dateInputValue.year.length === 4
                    ? moment.utc(dateStr, 'YYYY-MM-DD')
                    : undefined;

                props.onChange(date);
            }}
            value={input}
            error={props.error}
            label={props.label}
            data-component-id={props.dataComponentId}
            onBlur={props.onBlur}
        />);
};

export default DateInputRowWrapper;
