import { Versandart, Zahlweise } from '../consts'
import * as moment from 'moment'
import { OfferState } from './model/OfferState'
import {
  IAgentur,
  ICalculationRequest,
  ICalculationWithRiskRequest,
  IKooperationsPartner, IPerson
} from './model/request/ICalculationRequest'
import { IVariantUpdateRequest } from './model/request/IVariantUpdateRequest'
import { IPersonVn } from '../state/model/IPersonVn'
import { IPersonVp } from '../state/model/IPersonVp'

const toDateString = (date: moment.Moment) => date.format('YYYY-MM-DD')

const mapToCalculationWithRiskRequest = (
  vn: IPersonVn,
  vp: IPersonVp | undefined
): ICalculationWithRiskRequest => {
  const insuree: IPerson = {
    id: vn.id,
    birthdate: toDateString(vn.geburtsdatum as moment.Moment)
  }

  if (vp) {
    return {
      insuree,
      insuredPerson: {
        id: vp.id,
        birthdate: toDateString(vp.geburtsdatum as moment.Moment)
      }
    }
  }
  return {
    insuree
  }
}

const mapToCalculationRequest = (
  pricingModel: Zahlweise | undefined,
  vn: IPersonVn,
  vp: IPersonVp | undefined,
  insuranceStart: moment.Moment,
  agentur: IAgentur | undefined,
  actionId: string,
  kooperationsPartner: IKooperationsPartner | undefined,
  vermittlernummer: string | undefined,
  oeNummer: string,
  isInitialCalculation: boolean
): ICalculationRequest => {
  const result: ICalculationRequest = {
    pricingModel,
    ...mapToCalculationWithRiskRequest(vn, vp),
    insuranceStart: toDateString(insuranceStart)
  }

  if (isInitialCalculation) {
    result.actionId = actionId
    result.agentur = agentur
    result.kooperationsPartner = kooperationsPartner
    result.vermittlernummer = vermittlernummer
    result.oeNummer = oeNummer
  }

  return result
}

const mapInsuranceStartDates = (isoDates: string[]) => {
  return isoDates.map((d: string, index: number) => {
    return {
      insuranceStartDate: moment.utc(d),
      selected: index === 0
    }
  })
}

const mapToSelectVariantUpdateRequest = (variantId: string, pricingModel: Zahlweise): IVariantUpdateRequest => {
  return {
    variantId,
    pricingModel
  }
}

const mapDispatchToOfferState = (dispatch: Versandart) => {
  switch (dispatch) {
    case Versandart.Online:
      return OfferState.COMPLETE

    case Versandart.Email:
      return OfferState.MAIL

    case Versandart.Postalisch:
      return OfferState.POSTAL

    default:
      throw new Error(`${dispatch}: dispatch is unknown`)
  }
}

export {
  mapDispatchToOfferState,
  mapInsuranceStartDates,
  mapToCalculationWithRiskRequest,
  mapToCalculationRequest,
  mapToSelectVariantUpdateRequest,
}
