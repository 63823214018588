import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AdCampaignTeaser from '@eg/elements/AdCampaignTeaser';

type PromotionTeaserProps = {
    label: string,
    text: string,
    image: string
};

const PromotionTeaserPortal: React.StatelessComponent<PromotionTeaserProps> = (props: PromotionTeaserProps) => {
    const element = document.getElementById('promotion-teaser');

    if (element === null) {
        return null;
    }

    return ReactDOM.createPortal(
        <AdCampaignTeaser
            title={props.label}
            image={props.image}
        >
            {props.text}
        </AdCampaignTeaser>,
        element);
};

export default PromotionTeaserPortal;
