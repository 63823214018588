// careful: the variable-names has to match the aem-variables or else values wouldn't be tracked.
// this includes case-sensitivity and typos!

export class GlobalTariffTrackingElements {
    _produktgruppe: string = 'Kranken-Zusatz-Versicherung';
    _produkt: string | undefined;
    _tarifkurzbezeichnung: string | undefined;

    _vnVp: string | undefined;
    _versicherungsBeginn: string | undefined;
    _geburtsjahr: string | undefined;
    _geburtsjahrVp: string | undefined;

    _berechnungsErgebnis: string | undefined;
    _tarifOptionen1: string | undefined; // payment option

    _plz: string | undefined;
    _geschlecht: string | undefined;
    _name: string | undefined;
    _lastName: string | undefined;
    _emailAddress: string | undefined;
    _city: string | undefined;
    _firstLineAddress: string | undefined;
    _phoneNumber: string | undefined;
    _phoneNumberE164: string | undefined;
    _postalCode: string | undefined;

    constructor() {
        Object.defineProperty(this, 'Tarifkurzbezeichnung', {
            enumerable: true,
            get: () => this._tarifkurzbezeichnung,
            set: (value: string) => {
                this._tarifkurzbezeichnung = value;
            }
        });
        Object.defineProperty(this, 'Produktgruppe', {
            enumerable: true,
            get: () => this._produktgruppe,
            set: (value: string) => {
                this._produktgruppe = value;
            }
        });
        Object.defineProperty(this, 'Produkt', {
            enumerable: true,
            get: () => this._produkt,
            set: (value: string) => {
                this._produkt = value;
            }
        });
        Object.defineProperty(this, 'TarifOptionen1', {
            enumerable: true,
            get: () => this._tarifOptionen1,
            set: (value: string) => {
                this._tarifOptionen1 = value;
            }
        });
        Object.defineProperty(this, 'VnVp', {
            enumerable: true,
            get: () => this._vnVp,
            set: (value: string) => {
                this._vnVp = value;
            }
        });
        Object.defineProperty(this, 'VersicherungsBeginn', {
            enumerable: true,
            get: () => this._versicherungsBeginn,
            set: (value: string) => {
                this._versicherungsBeginn = value;
            }
        });
        Object.defineProperty(this, 'Geburtsjahr', {
            enumerable: true,
            get: () => this._geburtsjahr,
            set: (value: string) => {
                this._geburtsjahr = value;
            }
        });
        Object.defineProperty(this, 'GeburtsjahrVp', {
            enumerable: true,
            get: () => this._geburtsjahrVp,
            set: (value: string) => {
                this._geburtsjahrVp = value;
            }
        });
        Object.defineProperty(this, 'BerechnungsErgebnis', {
            enumerable: true,
            get: () => this._berechnungsErgebnis,
            set: (value: string) => {
                this._berechnungsErgebnis = value;
            }
        });
        Object.defineProperty(this, 'Plz', {
            enumerable: true,
            get: () => this._plz,
            set: (value: string) => {
                this._plz = value;
            }
        });
        Object.defineProperty(this, 'Geschlecht', {
            enumerable: true,
            get: () => this._geschlecht,
            set: (value: string) => {
                this._geschlecht = value;
            }
        });
        Object.defineProperty(this, 'Name', {
            enumerable: true,
            get: () => this._name,
            set: (value: string) => {
                this._name = value;
            }
        });
        Object.defineProperty(this, 'LastName', {
            enumerable: true,
            get: () => this._lastName,
            set: (value: string) => {
                this._lastName = value;
            }
        });
        Object.defineProperty(this, 'EmailAddress', {
            enumerable: true,
            get: () => this._emailAddress,
            set: (value: string) => {
                this._emailAddress = value;
            }
        });
        Object.defineProperty(this, 'City', {
            enumerable: true,
            get: () => this._city,
            set: (value: string) => {
                this._city = value;
            }
        });
        Object.defineProperty(this, 'FirstLineAddress', {
            enumerable: true,
            get: () => this._firstLineAddress,
            set: (value: string) => {
                this._firstLineAddress = value;
            }
        });
        Object.defineProperty(this, 'PhoneNumber', {
            enumerable: true,
            get: () => this._phoneNumber,
            set: (value: string) => {
                this._phoneNumber = value;
            }
        });
        Object.defineProperty(this, 'PhoneNumberE164', {
            enumerable: true,
            get: () => this._phoneNumberE164,
            set: (value: string) => {
                this._phoneNumberE164 = value;
            }
        });
        Object.defineProperty(this, 'PostalCode', {
            enumerable: true,
            get: () => this._postalCode,
            set: (value: string) => {
                this._postalCode = value;
            }
        });
    }
}
