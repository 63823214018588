import * as React from 'react';
import { Steps, szsAnnotation, Zahlweise } from '../../../consts';
import Tarifvariante from '../../../state/model/Tarifvariante';
import BigGreenCheck from './BigGreenCheck';
import { TooltipErsatzKrankenhaustagegeld, TooltipFreieArztwahl, TooltipRoomingIn, TooltipTariffInfoSzl, TooltipTariffInfoSzs } from '../../TooltipSZ';
import styled from 'styled-components';
import Price from '@eg/elements/components/Price';
import ComparisonTable from '@eg/elements/components/ComparisonTable';
import AnnotationContainer from '../../AnnotationContainer';
import Annotation from '../../Annotation';

const addBenefit = (text: string, szs: boolean, szl: boolean, tooltip?: JSX.Element) => {
    return (
        <tr data-component-id="benefit">
            <td>
                {text}
                {tooltip}
            </td>
            <td><BigGreenCheck/></td>
        </tr>
    );
};

interface ITariffTableRecalculatedDuesProps {
    isRoomingInVisible: boolean;
    selectedTariff: Tarifvariante;
    selectedPaymentOption: Zahlweise;
    selectedDue: number;
    onNavigate: (step: Steps) => void;
}

export const TariffTableRecalculatedDues = (props: ITariffTableRecalculatedDuesProps) => {

    const StyledContributionHeadline = styled.div`
        font-weight: bold;
        margin-bottom: 10px;
    `;

    const tableHead = props.selectedTariff === Tarifvariante.SZS ?
        (
            <th>
                <b>Günstiger Einstieg</b><br/>
                (SZS)<br/>
                <TooltipTariffInfoSzs/>
            </th>
        ) : (
            <th>
                <b>Stabiler Beitrag</b><br/>
                (SZL)<br/>
                <TooltipTariffInfoSzl/>
            </th>
        );

    const mapPaymentMethodToDisplayText = () => `${props.selectedPaymentOption.toLowerCase()}er`;

    return (
        <>
            <ComparisonTable selectedColumn={1} variant="rich">
                <thead>
                <tr>
                    <th/>
                    {tableHead}
                </tr>

                <tr>
                    <th>
                        <StyledContributionHeadline data-component-id="display-text-payment-method">
                            Ihr {mapPaymentMethodToDisplayText()} Zahlbeitrag
                        </StyledContributionHeadline>
                    </th>

                    <th>
                        <Price
                            value={props.selectedDue}
                            data-component-id="fee-duw"
                        />
                        {props.selectedTariff === Tarifvariante.SZS && <Annotation order={1} />}
                        <br/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {addBenefit('Freie Arztwahl', true, true, <TooltipFreieArztwahl/>)}
                {addBenefit('Ein- oder Zweibettzimmer', true, true)}
                {addBenefit('Ersatz-Krankenhaustagegeld bis zu 100 € pro Tag', true, true, <TooltipErsatzKrankenhaustagegeld/>)}
                {props.isRoomingInVisible && props.selectedTariff === Tarifvariante.SZL && addBenefit('Rooming-in', false, true, <TooltipRoomingIn/>)}
                </tbody>
            </ComparisonTable>
            <br/>
            {props.selectedTariff === Tarifvariante.SZS && <AnnotationContainer annotations={[szsAnnotation]}/>}
        </>
    );
};
