import { IAppState } from './model/AppState';
import { Zahlweise } from '../consts';
import Tarifvariante from './model/Tarifvariante';
import { IBeitrag } from './model/IBeitrag';
import { IVariante } from './model/IVariante';
import * as moment from 'moment';
import { IInsuranceStart } from './model/IInsuranceStart';
import { isVpYounger17 } from '../businessRules/roomingInRules';
import childAppStatus from './model/childAppStatus';
import { IBusinessRules } from './model/IBusinessRules';
import { IPersonendaten } from './model/IPersonendaten';

export function getSelectedPricingModel(state: IAppState): Zahlweise {
    const beitraege = [...state.angebot?.szs?.beitraege, ...state.angebot?.szl?.beitraege];
    const selectierterBeitrag = beitraege.find((beitrag) => beitrag.isSelected);

    return selectierterBeitrag ? selectierterBeitrag.zahlweise : Zahlweise.Monatlich;
}

export function getGewaehlteVariante(state: IAppState): Tarifvariante | undefined {
    if (
        state.angebot.szs &&
        state.angebot.szs.beitraege &&
        state.angebot.szs.beitraege.find((beitrag) => beitrag.isSelected)
    ) {
        return Tarifvariante.SZS;
    }
    if (
        state.angebot.szl &&
        state.angebot.szl.beitraege &&
        state.angebot.szl.beitraege.find((beitrag) => beitrag.isSelected)
    ) {
        return Tarifvariante.SZL;
    }
    return undefined;
}

export function getGewaehlteVarianteId(state: IAppState): string {
    if (state.angebot.szs?.beitraege?.find((beitrag) => beitrag.isSelected)) {
        return state.angebot.szs.id;
    }
    if (state.angebot.szl?.beitraege?.find((beitrag) => beitrag.isSelected)) {
        return state.angebot.szl.id;
    }
    throw new Error('Unknown chosen variant');
}

export function getBeitragByZahlweiseWithoutRisk(variante: IVariante, pricingModel: Zahlweise): number {
    if (variante.beitraege.length === 0) {
        return 0;
    }

    const beitraege: IBeitrag[] = variante.beitraege;
    const beitragNachZahlweise = beitraege.find((b) => b.zahlweise === pricingModel);

    if (beitragNachZahlweise === undefined) {
        throw new Error(`Zahlweise '${pricingModel}' is not available in variante.beitraege`);
    }

    return beitragNachZahlweise.betrag;
}

export function getBetrag(state: IAppState): number | undefined {
    const beitraege: IBeitrag[] = state.angebot.szs
        ? state.angebot.szs.beitraege.concat(state.angebot.szl.beitraege)
        : state.angebot.szl.beitraege;

    const result = beitraege.find((betrag) => betrag.isSelected);
    if (!result) {
        return undefined;
    }
    // Return 'betragAktualisiert', which exists only if risk premiums are added, otherwise return 'betrag'
    return result.betragAktualisiert || result.betrag;
}

export function isInsureeEqualToInsuredPerson(personenDaten: IPersonendaten): boolean {
    return personenDaten.vn.geburtsdatum !== undefined && personenDaten.vp === undefined;
}

export function getSelectedInsuranceStart(insuranceStartDates: IInsuranceStart[]): moment.Moment | undefined {
    const found = insuranceStartDates.find((d) => d.selected);
    return found ? found.insuranceStartDate : undefined;
}

export function isRoomingInAvailable(state: IAppState): boolean {
    const insuredPerson = state.angebot.personenDaten.vp;

    return insuredPerson
        ? isVpYounger17(
              insuredPerson.geburtsdatum,
              (state.businessRules as IBusinessRules).roomingInRules.dateSpanMaxForVpRoomingIn
          )
        : false;
}

export const isOnlineCompletionPossible = (state: IAppState): boolean =>
    // but what about checkout, doesn't it have to be checked?
    !state.isOnlineAbschlussDefekt && state.duwAppLoadingState !== childAppStatus.loadingFailed;
