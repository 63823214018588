import * as React from 'react';
import styled from 'styled-components';
import { CheckIcon } from '@eg/elements/Icons/CheckIcon';

const StyledCheckIcon = styled(CheckIcon)`
    color: #79b829;
    transform: scale(1.5);
`;

const BigGreenCheck = () => {
    return <StyledCheckIcon />;
};

export default BigGreenCheck;
