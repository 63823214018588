import * as React from 'react';
import MessageBox from '@eg/elements/components/MessageBox';
import Button from '@eg/elements/Button';
import ArrowRightIcon from '@eg/elements/components/Icons/ArrowRightIcon';
import { Steps } from '../../consts';
import Navigation from '../Navigation';
import styled from 'styled-components';

interface IErrorSwitchToOfferProps {
    onContinue: (step: Steps) => void;
}

const StyledParagraph = styled.p`
    padding-bottom: 10px;
    font-weight: bold;
`;

const ErrorSwitchToOffer: React.StatelessComponent<IErrorSwitchToOfferProps> = (props) => {
    return (
        <>
            <MessageBox>
                <div data-component-id="error_switch_to_offer_page">
                    <StyledParagraph>Derzeit ist ein Online-Abschluss leider nicht möglich.</StyledParagraph>
                    <p>
                        Sie können sich gerne ein Angebot zuschicken lassen, oder kontaktieren Sie uns kostenfrei unter &nbsp;
                        <b>0800&nbsp;/&nbsp;666&nbsp;9000</b>.
                    </p>
                    <p>Vielen Dank für Ihr Verständnis.</p>
                </div>
            </MessageBox>

            <Navigation
                ctaWeiter={(
                    <Button
                        iconRight={ArrowRightIcon}
                        data-component-id="button_weiter"
                        variant="primary"
                        onClick={() => props.onContinue(Steps.Persoenliches)}
                    >
                        Weiter
                    </Button>
                )}
            />
        </>
    );
};

export default ErrorSwitchToOffer;
