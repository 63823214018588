import { IConfig } from './IConfig';
import * as stateFunctions from '../state/stateFunctions';
import childAppStatus from '../state/model/childAppStatus';
import { duwAppLoadingState } from '../state/model/AppState';
import { getBackendHost } from './aem/aemConfigHelper'

export const getEnvVar = (name: string) => {
  if(window[name] === undefined) {
    if(process?.env[name]) {
      return process.env[name];
    }
    console.warn(`Missing environment variable: ${name}`);
  }
  return window[name];
};

const baseGatewayUrl = `${getBackendHost()}/api`;
const config: IConfig = {
  baseBackendUrl: baseGatewayUrl,
  urlPersonendaten: getEnvVar('CONFIG_PERSONDATA_JS'),
  urlCheckout: getEnvVar('CONFIG_CHECKOUT_JS'),
  urlDuw: getEnvVar('CONFIG_DUW_JS'),
  customFailHandlerCheckout: stateFunctions.onlineSalePreventingErrorOccured,

  customFailHandlerDuw: state => stateFunctions.changeChildAppLoadingState(state, childAppStatus.loadingFailed, duwAppLoadingState),

  errorPolicy: {
    configure: () => {
      window._trackJs = {
        application: 'szsszl',
        token: 'ac5193cbf5714332be98f2334a1552dd',
        console: {
          error: false
        }
      };
      const script = document.createElement('script');
      script.src = 'https://cdn.trackjs.com/releases/current/tracker.js';
      document.head.appendChild(script);
    },
    logError: (e) => {
      const trackJs = window.trackJs;
      if (trackJs) {
        trackJs.track(e);
      }
    }
  },

  loadCss: (theme: string) => {
    const link = document.createElement('link');
    const href = `${baseGatewayUrl}/public/static/css/${theme}.css`;

    link.href = href;
    link.rel = 'stylesheet';

    document.head.appendChild(link);

    const resolverFunction = (resolve: (value?: void | PromiseLike<void>) => void) => {
      const interval = setInterval(() => {
        const length = document.styleSheets.length;
        for (let i = 0; i < length; i++) {
          const css = document.styleSheets[i];
          if (css.href === href) {
            resolve();
            clearInterval(interval);
            break;
          }
        }
      },                           250);
    };
    return new Promise(resolverFunction);
  }

};
export default config;
