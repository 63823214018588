import * as React from 'react';
import Navigation from '../Navigation';
import { Button } from '@eg/elements/Button';
import DefaultButtonZurueck from '../DefaultButtonZurueck';
import { Steps, Zahlweise } from '../../consts';
import ArrowRightIcon from '@eg/elements/components/Icons/ArrowRightIcon';
import DuwInfo from './DuwInfo';
import { TariffTableRecalculatedDues } from './subcomponent/TariffTableRecalculatedDues';
import TariffVariant from '../../state/model/Tarifvariante';
import { DuwErgebnis } from '../../infrastructure/model/DuwErgebnis';
import { IRisiko } from '../../infrastructure/model/ITarifierung';

export interface IBeitragAktualisiertProps {
    isRoomingInAvailable: boolean;
    selectedTariff: TariffVariant;
    selectedPaymentMethod: Zahlweise;
    selectedDue: number;
    onNavigate: (step: number) => void;
    risiko: IRisiko;
    tracking: () => void;
}

class DueRecalculated extends React.Component<IBeitragAktualisiertProps> {

    constructor(props: IBeitragAktualisiertProps) {
        super(props);

        this.state = {
            isSubmitButtonLoading: false
        };
    }

    componentDidMount() {
        this.props.tracking();
    }

    render() {
        return (
            <>
                <br/>
                <h1>Ihr {this.props.risiko.duwErgebnis === DuwErgebnis.MANUELLE_RISIKOPRUEFUNG ? 'vorläufiger ' : ''}Beitrag</h1>

                <DuwInfo
                    duwResult={this.props.risiko.duwErgebnis}
                    exclusions={this.props.risiko.ausschluesse}
                    erhoehterBeitrag={this.props.risiko.hasErhoehtenBeitrag}
                />

                <TariffTableRecalculatedDues
                    data-component-id="tariff-table-after-risk-calculation"
                    isRoomingInVisible={this.props.isRoomingInAvailable}
                    onNavigate={this.props.onNavigate}
                    selectedTariff={this.props.selectedTariff}
                    selectedPaymentOption={this.props.selectedPaymentMethod}
                    selectedDue={this.props.selectedDue}
                />

                <Navigation
                    ctaBack={<DefaultButtonZurueck navigate={() => this.props.onNavigate(Steps.Gesundheitsfragen)}/>}
                    ctaWeiter={
                        <Button
                            data-component-id="button_weiter"
                            variant="primary"
                            iconRight={ArrowRightIcon}
                            onClick={() => this.props.onNavigate(Steps.Persoenliches)}
                        >
                            Online abschließen
                        </Button>
                    }
                />
            </>
        );
    }
}

export default DueRecalculated;
