import { mapToServiceModel } from './mapper/personendatenerfassungMapper';
import { ICallbacks, IPersonendatenInput } from './model/input/IPersonendatenInput';
import AnfrageTyp from './model/AnfrageTyp';
import IAngebot from '../../state/model/IAngebot';
import { THEME } from '@eg/elements/components/Provider';
import { IPromotionInput } from './model/input/IPromotionInput';

export interface IPersonendatenMessaging {
    dispatchRenderPersonendaten: (
        angebotsdaten: IAngebot,
        theme: THEME,
        apiRootUrl: string | undefined,
        callbacks: ICallbacks,
        promotion: IPromotionInput) => void;
    dispatchUnmountPersonendaten: () => void;
    dispatchNextClicked: (anfrageTyp: AnfrageTyp) => void;
}

export const personendatenMessaging: IPersonendatenMessaging = {
    dispatchRenderPersonendaten: (
        angebotsdaten,
        theme,
        apiRootUrl,
        callbacks,
        promotion) => {

        const offerData: IPersonendatenInput = mapToServiceModel(
            angebotsdaten,
            theme,
            apiRootUrl,
            callbacks,
            promotion);

        const changeEvent = new CustomEvent('renderPersonendaten', {detail: offerData});
        document.dispatchEvent(changeEvent);
    },
    dispatchUnmountPersonendaten: () => {
        document.dispatchEvent(new CustomEvent('unmountPersonendaten'));
    },
    dispatchNextClicked: (anfrageTyp) => {
        document.dispatchEvent(new CustomEvent('nextClicked', {detail: anfrageTyp}));
    }
};
