import * as React from 'react';
import { fetchContent } from '../../infrastructure/staticcontent/StaticContentRequests';
import PromotionFootnote from './PromotionFootnote';

interface IPromotionFootnoteContainerProps {
    footnoteUrl: string;
    footnoteContent: string;
    onFootnoteFetched: (content: string) => void;
}

class PromotionFootnoteContainer extends React.Component<IPromotionFootnoteContainerProps> {
    componentDidMount() {
        if (!this.props.footnoteContent) { // fetch footnote only when not available yet
            fetchContent(this.props.footnoteUrl)
                .then(content => this.props.onFootnoteFetched(content));
        }
    }

    render() {
        return <PromotionFootnote content={this.props.footnoteContent}/>;
    }
}

export default PromotionFootnoteContainer;