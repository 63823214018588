export default class TrackingEvents {

    static antragStart = { name: 'AntragStart', trackOnlyOnce: true };

    static antragAbschluss = { name: 'AntragAbschluss', trackOnlyOnce: true };

    static firstCalculation = { name: 'ErsteBerechnung', trackOnlyOnce: true };
    static furtherCalculations = { name: 'WeitereBerechnung', trackOnlyOnce: false };

    static personalData = { name: 'Personendaten', trackOnlyOnce: true };

    static offerViaEmail = { name: 'AngebotEmail', trackOnlyOnce: true };
    static offerViaPost = { name: 'AngebotPost', trackOnlyOnce: true };
    static pruefenUndSenden = { name: 'PruefenSenden', trackOnlyOnce: true };
}
