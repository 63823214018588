export enum TrackingEvents {
  PAGE_LOAD = "PageLoaded",
  GENERAL_CLICK = "GeneralClick",
  MARKETING = "MarketingLogicReady",
}

export enum ChannelType {
  ERGO_PAGE = "ERGO_PAGE",
  AGENT_BROKER = "AGENT_BROKER",
  MAKLER = "MAKLER",
  COOP_SALES_PARTNER = "COOP_SALES_PARTNER",
}

export interface TrackingContent {
  event?: TrackingEvents;
  miscellaneous?: TrackingErrors;
}

export interface TrackingErrorDetails {
  errorMessages: string;
  errorType: string;
  errorFlag?: boolean;
}

export interface TrackingErrors extends MarketingTrackingEventDetails {
  errors?: TrackingErrorDetails;
}

interface MarketingTrackingElements {
  actionNumber: string;
  agentNumber: string;
  salesCookie: string;
}

interface AgencyData {
  oenrNumber: string;
}

export interface MarketingTrackingEventDetails {
  marketing?: MarketingTrackingElements;
}

export type AgentWindow = Window &
  typeof globalThis & {
    appEventData?: TrackingContent[];
    agencyData?: AgencyData;
  };
