// careful: the variable-names has to match the aem-variables or else values wouldn't be tracked.
// this includes case-sensitivity and typos!

export default class EventBasedTarifTrackingElements {

    _viewName: string | undefined;
    _navigationsPfad: string | undefined;

    constructor() {
        Object.defineProperty(this, 'ViewName', {
            enumerable: true,
            get: () => this._viewName,
            set: (value: string) => { this._viewName = value; }
        });

        Object.defineProperty(this, 'NavigationsPfad', {
            enumerable: true,
            get: () => this._navigationsPfad,
            set: (value: string) => { this._navigationsPfad = value; }
        });
    }
}
