import * as React from 'react';
import Navigation from '../Navigation';
import { Button } from '@eg/elements/Button';
import { Steps, Zahlweise } from '../../consts';
import DefaultButtonZurueck from '../DefaultButtonZurueck';
import styled from 'styled-components';
import OrdersIcon from '@eg/elements/components/Icons/OrdersIcon';
import TarifdatenAendern from '../basicdata/TarifdatenAendern';
import { getSelectedInsuranceStart } from '../../state/helperFunctions';
import { TariffTable } from './subcomponent/TariffTable';
import ArrowRightIcon from '@eg/elements/components/Icons/ArrowRightIcon';
import IAngebot from '../../state/model/IAngebot';
import Tarifvariante from '../../state/model/Tarifvariante';
import moment from 'moment';

import { isDuwAgeConditionMetForErgo } from '../../businessRules/duwRules';

interface IDueProvisonallyState {
    isSubmitButtonLoading: boolean;
}

export interface IDueProvisonallyProps {
    angebot: IAngebot;
    isDuwPossible: boolean;
    isRoomingInAvailable: boolean;
    changeSelectedTariff: (newSelectedTariff: Tarifvariante) => void;
    changePaymentMethod: (paymentMethod: Zahlweise) => void;
    onChangeVariant: () => void;
    onNavigate: (step: number) => void;
    selectedPaymentMethod: Zahlweise;
    selectedTariffVariant?: Tarifvariante;
    tracking: () => void;
    isAgentur: boolean;
    hideRequestQuoteButton?: boolean;
}

class DueProvisonally extends React.Component<IDueProvisonallyProps, IDueProvisonallyState> {
    constructor(props: IDueProvisonallyProps, state: IDueProvisonallyState) {
        super(props, state);

        this.state = {
            isSubmitButtonLoading: false
        };

        this.requestOffer = this.requestOffer.bind(this);
    }

    navigate = (step: Steps) => {
        this.props.onChangeVariant();
        this.props.onNavigate(step);
    };

    handleSubmit = () => {
        this.setState((state) => {
            return { ...state, isSubmitButtonLoading: true };
        });

        if (this.props.isDuwPossible) {
            this.navigate(Steps.Gesundheitsfragen);
        } else {
            this.navigate(Steps.Persoenliches);
        }
    };

    requestOffer() {
        this.props.angebot.personenDaten.vn.iban = undefined;
        this.props.angebot.risiko = undefined;
        this.navigate(Steps.Persoenliches);
    }

    componentDidMount() {
        this.props.tracking();
    }

    duwPossibleLogicWrapper() {
        if (!this.props.isAgentur) {
            return !isDuwAgeConditionMetForErgo(this.props.angebot.szl.eintrittsalter, this.props.isAgentur);
        }
        return true;
    }

    render() {
        const StyledDiv = styled.div`
            float: right;
            clear: right;
        `;

        return (
            <>
                <TarifdatenAendern
                    dateOfBirthInsuree={this.props.angebot.personenDaten.vn.geburtsdatum as moment.Moment}
                    dateOfBirthInsuredPerson={
                        this.props.angebot.personenDaten.vp
                            ? this.props.angebot.personenDaten.vp.geburtsdatum
                            : undefined
                    }
                    insuranceStart={getSelectedInsuranceStart(this.props.angebot.insuranceStartDates) as moment.Moment}
                    onNavigate={() => this.props.onNavigate(Steps.Tarifdaten)}
                />

                <TariffTable
                    isRoomingInVisible={this.props.isRoomingInAvailable}
                    szs={this.props.angebot.szs}
                    szl={this.props.angebot.szl}
                    changeSelectedTariff={this.props.changeSelectedTariff}
                    selectedTariff={this.props.selectedTariffVariant}
                    changeSelectedPaymentOption={this.props.changePaymentMethod}
                    selectedZahlweise={this.props.selectedPaymentMethod}
                />

                <Navigation
                    hasConsultingInfo={true}
                    ctaBack={<DefaultButtonZurueck navigate={() => this.props.onNavigate(Steps.Tarifdaten)} />}
                    ctaWeiter={
                        <>
                            {this.duwPossibleLogicWrapper() && (
                                <>
                                    <Button
                                        data-component-id="button_weiter"
                                        variant="primary"
                                        onClick={this.handleSubmit}
                                        iconRight={ArrowRightIcon}
                                        disabled={this.props.selectedTariffVariant === undefined}
                                        loading={this.state.isSubmitButtonLoading}
                                    >
                                        {this.props.isDuwPossible ? 'Zur Gesundheitsprüfung' : 'Weiter'}
                                    </Button>
                                    <br />
                                </>
                            )}
                            {this.props.isAgentur !== true && this.props.hideRequestQuoteButton !== true && (
                                <StyledDiv>
                                    <Button
                                        data-component-id="button_angebot"
                                        onClick={this.requestOffer}
                                        variant="text-link"
                                        disabled={this.props.selectedTariffVariant === undefined}
                                        iconLeft={OrdersIcon}
                                    >
                                        Angebot anfordern
                                    </Button>
                                </StyledDiv>
                            )}
                        </>
                    }
                />
            </>
        );
    }
}

export default DueProvisonally;
