import { IPersonReference, ITarifierung } from './model/ITarifierung'
import { IProductBasics } from './model/IProductBasics';
import { IGatewayClient } from './IGatewayClient';
import { IFetchService } from './IFetchService';
import { IFetchServiceResponse } from './IFetchServiceResponse';
import {
    mapDispatchToOfferState,
    mapToCalculationRequest, mapToCalculationWithRiskRequest,
    mapToSelectVariantUpdateRequest
} from './gatewayMapper';
import { ICalculationRequest } from './model/request/ICalculationRequest';
import { IVariantUpdateRequest } from './model/request/IVariantUpdateRequest';
import { IPersonVn } from '../state/model/IPersonVn'
import { IPersonVp } from '../state/model/IPersonVp'
import { Zahlweise } from '../consts'

export const retry = async <T>(fn: () => T, times = 1, int = 500): Promise<T> => {
    const sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    try {
        return await fn();
    } catch (e) {
        if(times > 0) {
            await sleep(int);
            return retry(fn, times - 1, int*2);
        } else {
            throw e;
        }
    }
}

export const createClient = (fetchService: IFetchService, baseGatewayUrl: string): IGatewayClient => ({
    baseGatewayUrl,
    loadProductBasics: async () => {
        const response: IFetchServiceResponse = await fetchService.get(`${baseGatewayUrl}/productbasics`);
        return response.data as IProductBasics;
    },

    chooseVariant: async (businessId: string, tariffVariant: string, pricingModel: Zahlweise) => {
        const payload: IVariantUpdateRequest = mapToSelectVariantUpdateRequest(tariffVariant, pricingModel);
        await fetchService.put(`${baseGatewayUrl}/offers/${businessId}/variant`, payload);
    },
    choosePricingModel: async (businessId, pricingModel: Zahlweise): Promise<ITarifierung> => {
        const response = await fetchService.put(`${baseGatewayUrl}/offers/${businessId}/pricingmodel`, { pricingModel });
        return response.data as ITarifierung;
    },
    sendOffer: async (businessid, dispatch) => {
        const offerState = mapDispatchToOfferState(dispatch);
        await fetchService.put(`${baseGatewayUrl}/offers/${businessid}?state=${offerState}`);
    },
    checkOffer: async (businessId: string) => {
        const fn = async () => {
            return await fetchService.get(`${baseGatewayUrl}/offers/${businessId}/check`);
        }
        await retry(fn, 1, 500);
    },
    calculate: async (businessId, pricingModel:Zahlweise | undefined,  vn: IPersonVn, vp: IPersonVp | undefined, insuranceStart, agentur, actionId, kooperationsPartner, vermittlernummer, oeNummer) => {
        const payload: ICalculationRequest = mapToCalculationRequest(
            pricingModel,
            vn,
            vp,
            insuranceStart,
            agentur,
            actionId,
            kooperationsPartner,
            vermittlernummer,
            oeNummer,
            !Boolean(businessId));

        const fetchFx = businessId ? fetchService.put : fetchService.post;

        const response = await fetchFx(`${baseGatewayUrl}/calculation/${businessId}`, payload);
        return response.data as ITarifierung;
    },

    updatePersons: async (businessId: string, insuree: IPersonReference, insuredPerson?: IPersonReference) => {
        const payload = {
            insuree,
            insuredPerson
        };
        await fetchService.put(`${baseGatewayUrl}/offers/${businessId}/persons`, payload);
    },

    recalculateBasedOnUpdatedDuw: async (businessId, duwToken, vn: IPersonVn, vp: IPersonVp | undefined) => {
        const response = await fetchService.put(
            `${baseGatewayUrl}/calculation/${businessId}/risk`,
            mapToCalculationWithRiskRequest(vn, vp),
            {headers: {'X-Authorization': `Bearer ${duwToken}`}}
        );

        return response.data as ITarifierung;
    },

    resetDuw: async (businessId) => {
        await fetchService.delete(`${baseGatewayUrl}/offers/${businessId}/duw`);
    }
});
