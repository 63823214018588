import * as React from 'react';

interface IAnnotation {
    order: number;
}

const Annotation: React.StatelessComponent<IAnnotation> = (props) => {
    let asterix = '';
    for (let i = 0; i < props.order; i++) {
        asterix += '*';
    }

    return (
        <sup className="ee_annotation">{asterix}</sup>
    );
};

export default Annotation;
