import IPromotion from '../../state/model/IPromotion';
import { IPromotionInput } from '../../messaging/personendatenerfassung/model/input/IPromotionInput';

export const mapToPromotionInput = (tariff: string | undefined, aktionsnummer: string, promotion: IPromotion | undefined): IPromotionInput => {
    const isEnabled = promotion !== undefined;

    return {
        tariff,
        aktionsnummer,
        imageSource: isEnabled ? (promotion as IPromotion).imageSource : '',
        footnote: isEnabled ? (promotion as IPromotion).footnote as string : '',
        isEnabled
    };
};
