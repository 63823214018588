import childAppStatus from './childAppStatus';
import { IPersonendatenMeta } from './IPersonendaten';
import { IBusinessRules } from './IBusinessRules';
import { ICheckoutdaten } from './ICheckoutdaten';
import { Steps } from '../../consts';
import IAngebot from './IAngebot';
import Tarifvariante from './Tarifvariante';
import OfferParams from './OfferParams';

export const personenDatenAppLoadingState = 'personenDatenAppLoadingState';
export const checkoutAppLoadingState = 'checkoutAppLoadingState';
export const duwAppLoadingState = 'duwAppLoadingState';

export interface IAppState {
    isLoading: boolean;
    personenDatenAppLoadingState: childAppStatus;
    checkoutAppLoadingState: childAppStatus;
    duwAppLoadingState: childAppStatus;
    indexOfActiveStep: number;
    angebot: IAngebot;
    personenDatenMeta: IPersonendatenMeta;
    checkoutDaten: ICheckoutdaten;
    businessRules?: IBusinessRules;
    isOnlineAbschlussDefekt: boolean;
    promotionFootnote: string;
    offerParams: OfferParams;
}

export const createInitialAppState = (urlPersonendaten: string = '', urlChckout: string = '', urlDuw: string = ''): IAppState => {
    return {
        isLoading: true,
        personenDatenAppLoadingState: !urlPersonendaten ? childAppStatus.loadingSuccessfullyFinished : childAppStatus.loadingInProgress,
        checkoutAppLoadingState: !urlChckout ? childAppStatus.loadingSuccessfullyFinished : childAppStatus.loadingInProgress,
        duwAppLoadingState: !urlDuw ? childAppStatus.loadingSuccessfullyFinished : childAppStatus.loadingInProgress,
        indexOfActiveStep: Steps.Tarifdaten,
        angebot: {
            angebotId: '',
            insuranceStartDates: [],
            personenDaten: {
                vn: {
                    geburtsdatum: undefined,
                },
                vp: undefined
            },
            szs: {
                id: '',
                tarifvariante: Tarifvariante.SZS,
                beitraege: [],
                eintrittsalter: 0,
            },
            szl: {
                id: '',
                tarifvariante: Tarifvariante.SZL,
                beitraege: [],
                eintrittsalter: 0
            }
        },
        personenDatenMeta: {
            personendatenFuerAngebotKomplett: false,
            personendatenFuerOnlineAbschlussKomplett: false
        },
        checkoutDaten: {
            isValid: false
        },
        isOnlineAbschlussDefekt: false,
        promotionFootnote: '',
        offerParams: {
          oeNummer: '',
          vermittlernummer: '',
          aktionsnummer: '',
          channelType: '',
        },
    };
};
