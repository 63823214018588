import * as React from 'react';
import { default as App } from './App';
import Error from './feedback/Error';
import Provider from '@eg/elements/Provider';
import { IStaticValues } from '../state/model/IStaticValues';
import { IChildApp } from '../state/model/IChildApp';
import MessageBox from '@eg/elements/MessageBox';
import { IGatewayClient } from '../infrastructure/IGatewayClient';

interface IErrorBoundaryProps {
    personendaten: IChildApp;
    checkout: IChildApp;
    duw: IChildApp;
    className?: string;
    api: IGatewayClient;
    disableAemSpinner: () => void;
    staticValues: IStaticValues;
    logError: (e: Error | string) => void;
    scrollToTop: () => void;
}

export interface IErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {

    constructor(props: IErrorBoundaryProps) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    handleError = (e: string | Error, isLoggingTriggered: boolean = true) => {
        this.setState({
            hasError: true
        });

        if (isLoggingTriggered) {
            this.props.logError(e);
        }
    }

    componentDidCatch(e: Error) {
        // isLoggingTriggered=false: note that trackjs is logging everything that the error boundary catches automatically, so no need for further logging
        this.handleError(e, false);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Provider theme={this.props.staticValues.theme}>
                    <MessageBox type="error">
                        <Error/>
                    </MessageBox>
                </Provider>
            );
        }
        return <App {...this.props} onError={this.handleError}/>;
    }
}

export default ErrorBoundary;
