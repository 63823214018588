import {
    IAdditionalSzIddPdfRequestProperties,
    IBeitragsdaten,
    IBlock,
    ICallbacks,
    ICheckoutInput,
    ITarifdaten,
    IVersicherungsnehmer, PdfDownloadControls,
    Sparte
} from '../model/ICheckoutInput'
import { duwConsent, Steps, uiDateFormat, Zahlweise } from '../../../consts';
import { IPersonVn } from '../../../state/model/IPersonVn';
import * as moment from 'moment';
import { IPersonVp } from '../../../state/model/IPersonVp';
import Tarifvariante from '../../../state/model/Tarifvariante';
import { THEME } from '@eg/elements/components/Provider';
import IAdresse from '../../../state/model/IAdresse';

const anrede = {
    1: 'Frau',
    2: 'Herr'
};

const mapToEventModel = (
    theme: THEME,
    pdfDownloadControls: PdfDownloadControls,
    businessId: string,
    isAgentur: boolean,
    vn: IPersonVn,
    beitrag: number,
    zahlweise: Zahlweise,
    versicherungsbeginn: moment.Moment,
    onNavigate: (newStep: number) => void,
    isIddErrorMessageDisplayed: boolean,
    callbacks: ICallbacks,
    variante: Tarifvariante,
    duwHeadline: string,
    duwMountingPoint: string,
    apiRootUrl: string,
    vp?: IPersonVp,
): ICheckoutInput => {

    const onChangePersoenliches = () => {
        onNavigate(Steps.Persoenliches);
    };

    const mapVp = (): IBlock[] => {
        if (!vp) {
            return [];
        }

        const vpAdresse = (vp.adresse as IAdresse);

        return [{
            name: 'Persönliche Daten der versicherten Person',
            values: {
                'Anrede': vp.geschlecht !== undefined ? anrede[vp.geschlecht] : '',
                'Vorname': vp.vorname as string,
                'Nachname': vp.nachname as string,
                'Geburtsdatum': (vp.geburtsdatum as moment.Moment).format(uiDateFormat),
                'Straße / Nr.': `${vpAdresse.strasse} ${vpAdresse.hausnummer}`,
                'PLZ / Ort': `${vpAdresse.plz} ${vpAdresse.ort}`
            },
            onClick: onChangePersoenliches
        }];
    };

    const mapVn = (): IVersicherungsnehmer => {
        const vnAdresse = vn.adresse as IAdresse;

        return {
            anrede: vn.geschlecht !== undefined ? anrede[vn.geschlecht] : '',
            vorname: vn.vorname as string,
            nachname: vn.nachname as string,
            geburtsdatum: vn.geburtsdatum ? vn.geburtsdatum.format(uiDateFormat) : '',
            adresse: {
                strasse: vnAdresse.strasse,
                hausnummer: vnAdresse.hausnummer,
                plz: vnAdresse.plz,
                ort: vnAdresse.ort
            },
            eMail: vn.email as string,
            vorwahl: vn.vorwahl ? vn.vorwahl : '',
            rufnummer: vn.rufnummer ? vn.rufnummer : '',
            bankdaten: {
                iban: vn.iban as string,
                onBankverbindungChanged: onChangePersoenliches,
            },
            onVersicherungsnehmerChanged: onChangePersoenliches
        };
    };

    const mapBeitrag = (): IBeitragsdaten => {
        return {
            zahlweise,
            beitrag,
            onBeitragsdatenChanged: () => {
                onNavigate(Steps.Tarifdaten);
            }
        };
    };

    const mapTarifdaten = (): ITarifdaten => {
        return {
            versicherungsBeginn: versicherungsbeginn.format(uiDateFormat),
            blocks: {
                'Geburtsdatum': vn.geburtsdatum ? vn.geburtsdatum.format(uiDateFormat) : ''
            }
        };
    };

    const versicherungsnehmer = mapVn();

    const mapAdditionalIddPdfRequestProperties = (): IAdditionalSzIddPdfRequestProperties => {
        return {
            businessId,
            tarif: variante,
            vermittlerPDFMitschicken: !isAgentur
        };
    };

    return {
        theme,
        documentDownload: pdfDownloadControls,
        model: {
            versicherungsnehmer,
            beitragsdaten: mapBeitrag(),
            tarifdaten: mapTarifdaten(),
            blocks: mapVp(),
            extSubcomponents: [{
                headline: duwHeadline,
                mountingPoint: duwMountingPoint,
                onChangeData: () => {
                    onNavigate(Steps.Gesundheitsfragen);
                }
            }],
            consentBoxes: [duwConsent],
            sparte: Sparte.ERGO_KRANKENVERSICHERUNG,
        },
        isIddErrorMessageDisplayed,
        additionalIddPdfRequestProperties: mapAdditionalIddPdfRequestProperties(),
        callbacks,
        backendHost: apiRootUrl,
    };
};

export { mapToEventModel };
