import * as React from 'react';
import Newsletter from './Newsletter';
import { Betreuungsweiche } from '@eg/betreuungsweiche/betreuungsweiche';
import { getEnvVar } from '../../config/config'

interface IOnlineCompletionProps {
    formOfAddress: string;
    contactFormLink: JSX.Element;
    businessId: string;
    versicherungsnehmerPdsId: string;
    showBetreuungsweiche: boolean;
}

const OnlineCompletionText: React.FunctionComponent<IOnlineCompletionProps> = (props) => {
    return (
        <>
            <b>{props.formOfAddress}</b><br/><br/>
            schön, dass Sie sich für unseren Tarif entschieden haben. In Kürze bekommen Sie eine E-Mail und in wenigen Tagen auch Post vom Versicherer.
            <br/><br/>

            Fragen? Dann rufen Sie einfach unseren gebührenfreien Beratungs-Service an. Oder senden Sie uns eine {props.contactFormLink}.<br/><br/>

            {
                props.showBetreuungsweiche &&
                <>
                    <Betreuungsweiche
                        businessId={props.businessId}
                        versicherungsnehmerPdsId={props.versicherungsnehmerPdsId}
                        sparte="KRANKEN"
                        tarif="SZSSZL"
                        url={getEnvVar('CONFIG_BETREUUNGSWEICHE_URL')}
                    />
                </>
            }

            <Newsletter/>

        </>
    );
};

export default OnlineCompletionText;
